.chart-container {
  height: 100%;
  @media (max-width: 768px) {
    height: calc(100% - 26px);
    margin-left: -12px;
    margin-right: -8px;
  }
  iframe {
    height: 100%;
  }
}


.reset-chart {
  position: absolute;
  transform: translate(-50%,-50%);
  z-index: 10;
  color: #fff;
  cursor: pointer;
  right: 50px;
  bottom: 37px;
  @media (max-width: 768px) {
    transform: translate(0,0);
    bottom: 63px;
    left: 10px;
    right: auto;
  }
}

.chart-fp {
  position: absolute;
  top: 16px;
  right: 6px;
  display: none;
  z-index: 10;
  @media (max-width: 768px) {
    display: flex;
  }
}

.fullpage-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;

}