.strategy-settings-header {
  display: flex;
  width: 100%;
  height: 4vh;
  align-items: center;
  margin-bottom: 12px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  &__type {
    display: flex;
    line-break: normal;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    min-width: 82px;
    margin-left: 10px;
  }
  &__condition {
    margin-left: 12px;
    @media (max-width: 768px) {
      margin-left: 8px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 20px;
    width: 65px;
    padding-right: 5px;

    &_img {
      width: 24px;
      height: 24px;
      background-image: url(../../../../../../assets/images/Strategy/BackArrow.svg);
    }
  }
  & img {
    height: 100%;
    margin-right: 1vh;
    cursor: pointer;
  }
}
