.dropdownHeaderContainer {
    width: 142px;
    max-width: 142px;
    height: 44px;
    position: relative;
    background: rgba(23, 14, 50, 1);
    color: white;
    border-radius: 4px;
    cursor: not-allowed;

    @media (max-width: 768px) {
        max-width: 180px;
    }

    &__dropdown {
        height: 44px;
        overflow: hidden;
        transition-duration: 0.3s;
        z-index: 10;
        border-radius: 8px;
        &__item {
            width: 142;
            max-width: 142px;
            min-width: 142px;
            min-height: 44px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.3s;
            @media (max-width: 768px) {
                min-width: 180px;
                max-width: 180px;
            }
        }
        &__selected {
            width: 142;
            max-width: 142px;
            min-width: 142px;
            min-height: 44px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.3s;
            position: relative;
            cursor: not-allowed;
            user-select: none;
            border-radius: 4px;
            &__icon {
                position: absolute;
                top: 15px;
                bottom: 0;
                left: 0px;
                width: 18px;
                height: 18px;
                transition-duration: 0.3s;
                transform-origin: center;
                margin: 0 12px;
                @media (max-width: 768px) {
                    margin-left: 0;
                }
            }
            &__arrow {
                position: absolute;
                top: 15px;
                bottom: 0;
                right: 0px;
                width: 18px;
                height: 18px;
                transition-duration: 0.3s;
                transform-origin: center;
                margin: 0 12px;
                @media (max-width: 768px) {
                    margin-right: 0 !important;
                }
            }
            @media (max-width: 768px) {
                min-width: 180px;
                max-width: 180px;
            }
            &_active &__arrow {
                transform: rotate(180deg);
            }
        }
        &_opened {
            height: unset;
            // background-color: white;
            box-shadow: 2px 2px 15px 0 #00000026;
        }
        &:hover {
            // background-color: #d6d6d6;
        }
    }

    &__platformIcon {
        margin-right: 10px;
    }
}
