.thickness-custom-dropdown {
    display: flex;
    flex-direction: column;
    height: 28px;
    max-height: 28px;
    cursor: pointer;
    transition-duration: 0.2s;
    user-select: none;
    width: 100px;
    position: relative;
    &__header {
        text-align: center;
        font-family: Inter;
        font-size: 10px;
        font-weight: 700;
        line-height: 12.1px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 8px;
        position: absolute;
        width: 100px;
        top: -20px;
    }
    &__selected {
        z-index: 5;
        min-height: 28px;
        width: 100px;
        background: rgba(36, 27, 84, 1);
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        color: white;
        padding-left: 14px;
        border-radius: 6px;
        position: relative;
        margin-bottom: 5px;
        border: 1px solid rgba(99, 89, 154, 1);
        div {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
        }
        &_arrow {
            top: 50%;
            right: 5px;
            position: absolute;
            transform: translateY(-50%);
            transition-duration: 0.3s;
        }
        &_arrowOpened {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    &__itemsContainer {
        max-height: 180px;
        min-height: 180px;
        z-index: 10;
        overflow: scroll;

        border-radius: 6px;
        border: 1px solid rgba(99, 89, 154, 1);
        background: rgba(36, 27, 84, 1);
    }
    &__item {
        z-index: 6;
        min-height: 28px;
        width: 100%;
        background: rgba(36, 27, 84, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding-left: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        div {
            flex-shrink: 0;
            flex-wrap: nowrap;
        }
    }
    &__item:hover {
        background: rgb(67, 57, 122);
    }
}
