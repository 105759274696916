.mobileContentSwitcher {
  width: 100%;
  height: 8.5%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(19, 14, 45, 1);

  color: white;
  z-index: 5;
}
.mobileContentSwitcherItem {
  width: 12%;
  height: 100%;
  background-color: transparent;
  border-radius: 8px;
  transition-duration: 0.3s;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: center;
  white-space: nowrap;
}
.mobileContentSwitcherItem img {
  border-radius: 3px;
  margin-bottom: 4px;
  padding: 3px;
}
.mobileContentSwitcherItemActive {
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 12.1px;
  text-align: center;
}
.mobileContentSwitcherItemActive img {
  /* background: #da47ff; */
}
