.mainContent {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.historyWrapper {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 18px 8px 18px 12px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .historyWrapper {
    padding: 8px 8px 0px 12px;
    background: rgba(10, 7, 26, 1);
    border-radius: 0px;
  }
  .fullpage-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% ;
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    z-index: 1000;
  }
}

.historyWrapperFullScreen {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.historyHeadWrapper {
  display: flex;
  margin: 0 0 14px;
  @media (max-width: 768px) {
    .date-dropdown {
      margin-right: 30px;
    }
  }
}

.tabsWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .tabsWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.additionalButtonWrapper {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  display: none;
}

.additionalButtonWrapper button {
  background-color: transparent;
  border: none;
}

.additionalButtonWrapper button svg {
  width: 24px;
  height: 24px;
}

.downloadBtn {
  margin: 0 12px 0;
  z-index: 3;
}

.mainTab {
  margin: 0;
  width: 212px;
  position: relative;
  z-index: 3;
}

.chartTab button {
  font-size: 10px;
  padding: 4px 18px;
}

.columnLabel:first-child {
  padding: 0 18px 0 0;
}

.columnLabel:nth-child(2) {
  padding: 0 18px 0;
}

.columnValue {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.columnValue:nth-child(3),
.columnValue:nth-child(4) {
  border-left: 1px solid #cdcdcd;
}

.table thead tr:nth-child(2) th {
  padding: 0 32px 12px;
}

.table tbody td {
  text-align: center;
}

.table tbody tr:not(.table tbody tr:first-child) td {
  padding: 18px 0 0;
}

.tableWrap {
  width: 250px;
  overflow: auto;
  scrollbar-width: none;
  padding-right: 15px;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.columnLabel {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  padding: 0 32px 0;
  text-align: center;
}

.rowLabel {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: start !important;
}

.additionalCommonValue {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.additionalValue {
  font-size: 8px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.redColor {
  color: #f0315f !important;
}

.greenColor {
  color: #30d95f !important;
}

.strategyTesterExtraTabs {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .strategyTesterExtraTabs {
    justify-content: flex-start;
    flex-direction: column-reverse;
    align-items: flex-start;
    z-index: 5;
  }
}

.chartTimeTab {
  margin: 0;
  width: auto !important;
  min-width: 1px !important;
}

.mainContentWrapper {
  height: calc(100% - 38px);
  &.touched {
    overflow: hidden;
  }
}

.chartTimeTab button {
  width: 78px !important;
}

.chartBarWrapper {
  width: 100%;
}

.chartBar {
  width: 100%;
}

.strategyTasterBackgroundWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.strategyTasterBackgroundWrapper img {
  width: 100%;
  height: 100%;
  mix-blend-mode: difference;
}

.strategyTasterBackgroundWrapper button {
  z-index: 3;
  font-size: 12px;
  font-weight: 700;
  padding: 14px;
}

.strategyTasterBackgroundWrapper h2 {
  z-index: 3;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0 0 12px 0;
}

.strategyTasterBackgroundWrapper p {
  z-index: 3;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0 0 18px 0;
}

.chartTab {
  width: auto !important;
  min-width: 1px !important;
  margin-left: 10px;
  margin-right: 8px;
  button {
    min-width: 24px !important;
    min-height: 24px !important;
    max-width: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 4px;
    color: transparent;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
    &:nth-child(1) {
      &:before {
        background: url("../../../../assets/icons/candlestick_light.svg") center center / contain;
      }
    }
    &:nth-child(2) {
      &:before {
        background: url("../../../../assets/icons/line_up_light.svg") center center / contain;
      }
    }
    &:not(:last-child) {
      margin-right: 4px;
      @media screen and (max-width: 768px) {
        margin-right: 12px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .mainContentWrapper {
    width: 100%;
    overflow: scroll;
    scrollbar-color: #9747ff;
    padding-bottom: 0;
    background-color: rgba(10, 7, 26, 1);
    scrollbar-width: none !important;
  }
  .mainContentWrapper::-webkit-scrollbar {
    height: 0px !important;
  }
  .mainContentWrapper::-webkit-scrollbar {
    display: none !important; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  }
  .mainContentWrapper::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  .mainContentWrapper {
    overflow: auto;
  }
  .historyContainer {
    width: 300%;
    height: 100%;
  }
  .chartTab {
    margin-left: 0px;
  }
  .chartTab button {
    width: 70px !important;
    max-width: 70px !important;
    height: 24px !important;
    color: white;
    margin-right: 12px;
  }
  .chartTab button::before {
    color: white;
    left: 13.5px;
    font-family: Inter;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    text-align: left;
    position: static;
    transform: unset;
    margin-right: 5px;
  }
  .datePickerMobContainer {
    display: flex;
    align-items: center;
  }
}
.tabsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .tabsContainer {
    width: 100%;
  }
}
.fakeScrollBarContainer {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 95%;
  height: 6px;
  background: rgba(28, 22, 61, 1);
  display: none;
  border-radius: 6px;
  margin-left: 12px;
  z-index: 2;
}
.fakeScrollBar {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 33.33%;
  max-width: 33.33% !important;
  height: 6px;
  background: #9747ff;
  display: none;
  border-radius: 6px;
  margin-left: 12px;
  z-index: 3;
}

@media (max-width: 768px) {
  .fakeScrollBar {
    display: block;
  }
  .fakeScrollBarContainer {
    display: block;
  }
}
