.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  width: 100%;
  padding: 8px;
  padding-top: 0px;
  background: rgba(19, 14, 45, 1);
  overflow: scroll;

  @media screen and (max-width: 768px) {
    padding: 0px;
    gap: 0px;
  }
  scrollbar-width: none;
}
.chartWrapperFullScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
.content,
.navigation {
  position: relative;
  /* background-color: white; */
  /* border-radius: 8px; */
  overflow: hidden;
  /* padding-top: 8px; */
}

.navigation {
  height: 60px;
  min-height: 60px;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.rowContainer,
.columnContainer {
  display: flex;
  position: relative;
  /* background: rgb(19, 14, 45); */
}
.constructorContent {
  overflow: hidden;
}
.chart_Mobcontainer {
  max-height: 91.5%;
  height: 91.5%;
}
.chart_Mobcontainer_full {
  max-height: 100%;
  height: 100%;
}
.historyMainContainer {
  width: 100%;
  height: 37.5%;
  border: 1px solid white;
  overflow: scroll;
}
.historyContainer {
  width: 300%;
  height: 100%;
}
.mobileHomePageHeaderText2 {
  min-width: 83px;
  min-height: 28px;
  width: 83px;
  height: 28px;
  background: #694ef0;
  border-radius: 4px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-left: 16px;
  margin-bottom: 16px;
}
@media screen and (max-width: 768px) {
  .content {
    max-height: 100%;
    height: 100%;
  }
  .rowContainer {
    max-height: 99.5%;
    height: 99.5%;
    background: rgba(10, 7, 26, 1);
  }
  .constructorContent {
    max-height: 91.5%;
    height: 91.5%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .mobileHomePage {
    background-image: url(../../assets/images/MobileHomePageBackground.svg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .mobileHomePageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    margin-bottom: 18px;
  }
  .mobileHomePageHeaderText {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    display: flex;
    align-items: center;
  }
  .mobileHomePageHeaderText img {
    transform: rotate(180deg);
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }
  .mobileHomePageHeaderNavItem {
    width: 100%;
    height: 84px;
    background: rgba(68, 63, 94, 0.5);
    padding: 24px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin-bottom: 10px;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
  }
  .mobileHomePageHeaderNavItemName img {
    margin-right: 12px;
  }
  .mobileHomePageHeaderNavItemName {
    display: flex;
    align-items: center;
  }
  .mobileHomePageHeaderNavItemNameDisabled {
    opacity: 0.5;
  }
  .mobileHomePageHeaderNavItemNameDisabled {
  }

  .constructorContentHeader {
    display: flex;
    align-items: center;
    gap: 1vh;
    cursor: pointer;
    min-width: 170px;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 21.94px;
    text-align: left;
    color: white;
    padding-top: 22px;
    background: rgba(10, 7, 26, 1);
    padding-left: 12px;
  }
  .columnContainer {
    max-height: 91.5%;
    height: 91.5%;
  }
}

.columnContainer {
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.resizeHandleVertical {
  max-width: 14px;
  width: 14px;
}

.resizeHandleHorizontal {
  height: 14px;
  max-height: 14px;
}
.mobileContentSwitcher {
  width: 100%;
  height: 8.5%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(19, 14, 45, 1);

  color: white;
  /* position: absolute;
  left: 0;
  bottom: 0; */
  z-index: 5;
}
.mobileContentSwitcherItem {
  width: 12%;
  height: 100%;
  background-color: transparent;
  border-radius: 8px;
  transition-duration: 0.3s;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: center;
  white-space: nowrap;
}
.mobileContentSwitcherItem img {
  width: 28px;
  height: 28px;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 3px;
}
.mobileContentSwitcherItemActive {
  color: rgb(151, 71, 255);
}
.mobileContentSwitcherItemActive img {
  background: rgb(151, 71, 255);
}
.mobileContainer {
  height: 100%;
}
.chartInfoSelectorTicker {
  display: flex;
  align-items: center;
  gap: 1vh;
  cursor: pointer;
  min-width: 170px;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 21.94px;
  text-align: left;
  color: white;
  padding-top: 22px;
  background: rgba(10, 7, 26, 1);

  padding-left: 12px;
}
.mainContentWrapper {
  height: calc(100% - 38px);
}
@media screen and (max-width: 768px) {
  .mainContentWrapper {
    width: 100%;
    overflow: scroll;
    height: 70%;
    scrollbar-color: #9747ff;
    padding-bottom: 0;
    background-color: rgba(10, 7, 26, 1);
    scrollbar-width: none !important;
  }
  .mainContentWrapper::-webkit-scrollbar {
    height: 0px !important;
  }
  .mainContentWrapper::-webkit-scrollbar {
    display: none !important; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  }
  .mainContentWrapper::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  .mainContentWrapper {
    overflow: auto;
  }
}
.homeSwitcher {
  display: flex;
  background: #262336;
  align-items: center;
  border-radius: 6px;
  gap: 1px;
  height: 40px;
  width: 100%;
  color: white;
  padding: 0px 5px 0px 5px;
  min-height: 40px;
  margin-bottom: 13px;
}
.homeSwitcherOption {
  font-size: 14px;
  font-weight: 600;
  flex: 1 1 0;
  width: 100px;
  white-space: nowrap;
  cursor: pointer;
  padding: 0 10px 5px 10px;
  height: 28px;
  line-height: 28px;
  border-radius: 6px;
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0px;
  height: 32px;
  line-height: 32px;
}

.strategyBtn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background: rgba(105, 78, 240, 1);
  white-space: nowrap;
  padding: 0 12px;
  height: 32px;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 0px;
  z-index: 3;
  cursor: pointer;
}
.strategyBtn img {
  margin-left: 10px;
}

.chartMobileContainer {
  height: calc(100% - 40px);
}
.chartMobileContainerCustomIndex {
  height: calc(100% - 77px);
}
.chartMobileContainerCustomIndexMerged {
  height: calc(100% - 96px);
}
.chartMobileContainerCustomIndexMergedCustomIndex {
  height: calc(100% - 115px);
}
