.strategy-modes {
  width: 95%;
  margin: 0 auto;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  &-mode {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  
    padding: 12px 14px;

    &__name {
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      color: white;
      text-transform: uppercase;
    }

    &__preview {
      width: 100%;
    }

    &__description {
      color: white;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
    }

    &__select {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 140px;
      height: 44px;
      border-radius: 4px;
      margin: 0 auto;
      //styleName: Button text/Button Active;
      font-family: Inter;
      font-size: 10px;
      font-weight: 600;
      line-height: 12.1px;
      text-align: left;
      background: rgba(105, 78, 240, 1);
      color: white;
      cursor: pointer;
    }
  }
}
