.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 268px;
    height: 64px;
    border-radius: 8px;
    margin: 7px;
    font-size: 16px;
    padding-left: 64px;
    padding-right: 10px;
    font-style: normal;
    font-weight: 500;
    transition-duration: 0.3s;
    background: linear-gradient(to right, rgba(23, 14, 50, 1), rgba(70, 43, 152, 1));
    color: #fff;
    img[alt="AddIcon"] {
        width: 44px;
        height: 44px;
        object-fit: contain;
    }
    &__connectionIndicator {
        width: 95px;
        height: 24px;
        position: absolute;
        top: 15px;
        left: -105px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        &__circle {
            min-width: 12px;
            min-height: 12px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-left: 5px;
            &_connected {
                background: #30d95f;
            }
            &_notConnected {
                background: #f21212;
            }
        }
        @media (max-width: 768px) {
            top: 20px;
            left: -155px;
            width: 75px;
            text-align: left;
        }
    }
    &__settingsIndicatior {
        position: absolute;
        top: 15px;
        right: -50px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        transition-duration: 0.3s;
        &:hover {
            transform: translateY(-50%) rotate(45deg);
        }
        @media (max-width: 768px) {
            right: unset;
            left: -50px;
        }
    }

    img {
        margin-right: 18px;
    }
    @media (max-width: 768px) {
        width: 180px;
        height: 54px;
        border-radius: 6px;
        margin: 9px;
        font-size: 12px;
        line-height: 12px;
        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }

        &__connectionIndicator {
            display: flex;
            top: calc(100% + 7px);
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
}
.popUpContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @media (max-width: 768px) {
        background-color: white;
    }
}
.popUp {
    width: 90%;
    height: 76%;
    max-width: 955px;
    border-radius: 12px;
    background: #170e32;
    padding: 32px;
    padding-top: 40px;
    position: relative;
    overflow-x: scroll;
    scrollbar-width: none;
    @media (max-width: 768px) {
        max-width: 410px;
        width: 100%;
        min-height: 100%;
        overflow: scroll;
        border-radius: 0;
        padding: 54px 14px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &__header {
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 45px;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    &__platformContainer {
        display: flex;
        margin-bottom: 30px;
        width: 100%;
        @media (max-width: 768px) {
            overflow-x: scroll;
            scrollbar-width: none;
            margin-bottom: 13px;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__platform {
        width: 142px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        margin-right: 8px;
        user-select: none;
        cursor: pointer;
        color: #fff;
        img {
            width: 24px;
            height: 24px;
            margin-right: 18px;
        }
        &__selected {
            background: linear-gradient(to right bottom, rgb(23, 14, 50), rgb(70, 43, 152));
            color: #fff;
        }
        &__disabled {
            cursor: not-allowed;
            background: #261850;
        }
        @media (max-width: 768px) {
            width: 121px;
            min-width: 121px;
            height: 38px;
            font-size: 10px;
            font-weight: 700;
            margin: unset;
            margin-right: 8px;
            img {
                width: 18px;
                height: 18px;
                margin-right: 18px;
            }
        }
    }
    &__closeButton {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-image: url("../../../../assets/images/MenuPage/CloseIcon.svg");
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-content: center;
        @media (max-width: 768px) {
            width: 24px;
            height: 24px;
            top: 18px;
            right: 12px;
        }
    }
    &__table {
        width: 100%;
        margin-bottom: 18px;
        @media (max-width: 768px) {
            max-width: 360px;
            margin: auto;
        }
    }
    &__tableHeader {
        background-color: #261850;
        border-radius: 4px;
        color: #fff;
    }
    &__tableRow {
        display: flex;
        padding-top: 2px;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        @media (max-width: 768px) {
            flex-wrap: wrap;
            height: 23px;
            padding-top: 0;
            div {
                height: 23px;
            }
        }
    }
    &__tableRow > div:nth-child(1) {
        flex-basis: 2%;
        padding-left: 4px;
        @media (max-width: 768px) {
            flex-basis: 8%;
            font-size: 12px;
            font-weight: 600;
            padding-left: 0;
        }
    }
    &__tableRow > div:nth-child(2) {
        flex-basis: 16%;
        justify-content: flex-start;
        padding-left: 17px;
        @media (max-width: 768px) {
            flex-basis: 17%;
            font-size: 12px;
            font-weight: 600;
        }
    }
    &__tableRow > div:nth-child(3) {
        flex-basis: 6%;
        justify-content: flex-start;
        @media (max-width: 768px) {
            justify-content: center;
            flex-basis: 48%;
            font-size: 12px;
            font-weight: 600;
        }
    }
    &__tableRow > div:nth-child(4) {
        flex-basis: 14%;
        @media (max-width: 768px) {
            display: none;
        }
    }
    &__tableRow > div:nth-child(5) {
        flex-basis: 7%;
        @media (max-width: 768px) {
            display: none;
        }
    }
    &__tableRow > div:nth-child(6) {
        flex-basis: 18%;
        margin-right: 11%;
        @media (max-width: 768px) {
            display: none;
        }
    }
    &__tableRow > div:nth-child(7) {
        flex-basis: 25%;
        @media (max-width: 768px) {
            flex-basis: 25%;
            font-size: 12px;
            font-weight: 600;
            justify-content: flex-end;
        }
    }
    &__tableEditRow {
        display: flex;
        height: 100px;
        border-radius: 4px;
        background-color: #f4f4f4;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            color: #000;
            font-family: Nunito;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-bottom: 20px;
        }
    }
    &__tableEditRow > div:nth-child(1) {
        flex-basis: 3%;
    }
    &__tableEditRow > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        box-sizing: border-box;
        height: 100%;
        margin-right: 10px;
        input {
            width: 100%;
            height: 32px;
            background-color: white;
            outline: none;
            border: none;
            margin-top: 8px;
            border-radius: 4px;
            color: #838383;
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            padding-left: 18px;
        }
    }
    &__tableEditRow > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-basis: 30%;
        margin-right: 12%;
        box-sizing: border-box;
        height: 100%;
        input {
            width: 100%;
            height: 32px;
            background-color: white;
            outline: none;
            border: none;
            border-radius: 4px;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 18px;
        }
        input::placeholder {
            color: #838383;
            font-size: 10px;
            font-style: normal;
        }
    }
    &__tableEditRow > div:nth-child(4) {
        flex-basis: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
