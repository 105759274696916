.mobileHomePageContainer {
  padding: 32px 16px 0px 16px;
}

.mobileHomePageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  margin-bottom: 18px;
}
.mobileHomePageHeaderText {
  color: white;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  display: flex;
  align-items: center;
}
.mobileHomePageHeaderText img {
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
  margin-right: 3px;
}
.mobileHomePageHeaderNavItem {
  width: 100%;
  height: 84px;
  background: rgba(68, 63, 94, 0.5);
  padding: 24px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin-bottom: 10px;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.mobileHomePageHeaderNavItemName img {
  margin-right: 12px;
}
.mobileHomePageHeaderNavItemName {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: white;
  text-decoration: none;
}
.mobileHomePageHeaderNavItemName img {
  width: 30px;
  height: 30px;
}
.mobileHomePageHeaderNavItemNameDisabled {
  opacity: 0.5;
}
.mobileHomePageHeaderNavItemNameDisabled {
}
