.g-select {
  position: relative;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  &__label {
    flex-shrink: 0;
    flex-basis: 30%;
    font-size: 16px;
    font-weight: 700;
    color: #fff;

    translate: 0 14px;

    @media (max-width: 1100px) {
      flex-basis: 40%;
    }
  }

  &-input {
    flex-basis: 100%;

    display: flex;
    flex-direction: column;

    border-radius: 8px;

    background-color: #262336;
    color: #fff;

    cursor: pointer;

    &-choosen {
      position: relative;

      &::after {
        content: '';

        position: absolute;
        bottom: 0;
        left: 50%;
        translate: -50% 0;

        width: 80%;
        height: 1px;

        background-color: var(--color-gray-hover);

        transition: .2s;
      }
    }

    &__expand {
      position: absolute;
      right: 14px;
      top: 50%;
      translate: 0 -50%;

      opacity: .25;

      transition: .2s;
      path {
        stroke: #CBCBCB;
      }
    }

    &-list {
      padding: 10px 10px 10px 0;

      &__wrapper {
        max-height: 7rem;
        overflow: auto;

        transition: .2s;

        &::-webkit-scrollbar {
          appearance: none;
          width: 4px;
          border-radius: 2px;

          background-color: var(--color-gray-hover);
        }

        &::-webkit-scrollbar-thumb {
          width: 100%;
          border-radius: 2px;

          background-color: #9747FF;
        }
      }

      &-element {
        opacity: .5;

        transition: .2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &:hover &-input {
    &__expand {
      opacity: 1;
    }
  }

  &_collapsed &-input {
    &-choosen::after {
      opacity: 0;
    }

    &__expand {
      rotate: 180deg;
    }

    &-list {
      padding: 0;

      &__wrapper {
        max-height: 0;
        overflow: hidden;
      }
    }
  }

  &-element {
    display: flex;
    align-items: center;
    gap: 20px;

    padding: 14px 14px 14px 18px;

    &__icon {
      height: 26px;
      width: 26px;

      & img {
        height: 100%;
        width: 100%;
      }
    }

    &__name {
      font-size: 16px;
      font-weight: 800;
      color: #fff;
    }

    &_disabled {
      opacity: .25;
      cursor: not-allowed;

      &:hover {
        opacity: .25;
      }
    }
  }
}