.strategy {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5vh;
  height: calc(100% - 100px);
  // padding-bottom: 50px;
  @media screen and (max-width: 768px) {
    height: 100%;
    padding-bottom: 0px;
  }
  &_hedgeContainer{
    width:100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:17px;
    @media screen and (max-width: 768px) {
      gap:20px;
    }

  }
  &__SwitcherNameContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    &__switcher {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &__name {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  &__strategiesSwitcher {
    width: 95%;
    height: 48px;
    background-color: #262336;
    border-radius: 4px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      margin-bottom: 14px;
    }
    &__item {
      width: 50%;
      height: 44px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0px;
    }
  }
  &__propertiesContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    width: 100%;
    position: relative;
    background-color: rgb(19, 14, 45);
  }
  &__main {
    width: 100%;
    // padding-bottom: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.65vh;
    overflow: scroll;
    flex:1;
    @media screen and (max-width: 768px) {
      gap: 0px;
      padding-bottom: 0px;
    }

    &__elem {
      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5vh;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      color: white;
      @media screen and (max-width: 768px) {
        margin-bottom: 16px;
        margin-top: 16px;
      }
      &__title {
        text-align: left;
        width: 100%;
      }
    }

    &__inline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 5%;
      color: white;
      @media screen and (max-width: 768px) {
        min-height: 66px;
      }
      & h4 {
        white-space: nowrap;
        margin-right: auto;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
      }

      &-input {
        margin-left: auto;
        width: 100px;
        height: 38px;
        padding: 0 10px;
        outline: none;
        border: none;
        background: #262336;
        border-radius: 4px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: center;
        color: white;
      }
    }
  }

  &__footer {
    position: absolute;
    width: 100%;
    height: 70px;
    padding-bottom: 25px;
    padding-top: 20px;
    background-color: rgb(19, 14, 45);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    bottom: 0;
    z-index: 10;

    @media screen and (max-width: 768px) {
      padding-top: 10px;
      width: 95%;
      position: static;
      flex-direction: column;
      height: 130px;
    }
    & button {
      outline: none;
      cursor: pointer;
      transition-duration: 0.2s;
      max-width: 47%;
      width: 205px;
      height: 40px;
      border-radius: 6px;
      gap: 10px;
      letter-spacing: 0px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
    }

    &-save {
      background: #30d960;
      color: "black";
      @media screen and (max-width: 768px) {
        max-width: 48% !important;
        width: 48% !important;
      }
      &:hover {
        background: #4ef37d;
      }
    }

    &-automatize {
      border: 1px solid rgba(44, 39, 70, 1);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        max-width: 48% !important;
        width: 48% !important;
      }
      &:hover {
        background: rgba(44, 39, 70, 1);
      }
    }
  }
  &__saveButtonContainer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    background-color: rgba(19, 14, 45, 0.9);
    z-index: 5;
    @media screen and (max-width: 768px) {
      position: static;
      padding: 0 5%;
    }
  }
  &__saveButton {
    width: 140px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #694ef0;
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    bottom: 40px;
    cursor: pointer;
  }
  &__settingsIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    background-image: url(../../../../../../assets/images/Strategy/SettingsIcon.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
