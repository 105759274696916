.tgConnectionContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    background: rgba(0, 0, 0, 0.4);
}
.tgConnection {
    width: 712px;
    max-height: 712px;
    height: 69%;
    border-radius: 12px;
    padding: 32px 24px 50px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(23, 14, 50, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    @media (max-width: 768px) {
        height: 93.5%;
        min-height: 93.5%;
        max-height: 93.5%;
        width: 90%;
        padding: 12px;
    }

    &__closeButton {
        position: absolute;
        top: 32px;
        right: 24px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-image: url("../../../../../assets/icons/CloseIconWhite.svg");
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-content: center;
        @media (max-width: 768px) {
            width: 24px;
            height: 24px;
            top: 12px;
            right: 12px;
        }
    }
    &__header {
        font-family: Inter;
        font-size: 28px;
        font-weight: 700;
        line-height: 33.89px;
        text-align: center;
        margin-bottom: 32px;
        @media (max-width: 768px) {
            margin-top: 8%;
            font-size: 20px;
            line-height: 24.2px;
            max-width: 300px;
            margin-bottom: 8px;
        }
    }
    &__subHeader {
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: center;
        margin-bottom: 25px;
        @media screen and (max-width: 768px) {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: center;
            max-width: 300px;
            margin-bottom: 8.5%;
        }
    }
    &__text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: center;
        margin-bottom: 12px;
        @media (max-width: 768px) {
            font-family: Inter;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.52px;
            text-align: center;
        }
    }
    &__openTgButton {
        width: 220px;
        height: 64px;
        border-radius: 8px;
        border: 1px solid rgba(105, 78, 240, 1);
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        transition-duration: 0.3s;
        margin-bottom: 24px;
        img {
            margin-left: 8px;
        }
        @media (max-width: 768px) {
            width: 200px;
            height: 54px;
            min-height: 50px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            margin-bottom: 5%;
        }
    }
    &__openTgButton:hover {
        box-shadow: 0 0 5px 0 rgba(105, 78, 240, 1);
    }
    &__qrText {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: center;
        margin-bottom: 25px;
        @media (max-width: 768px) {
            margin-bottom: 4.5%;
        }
    }
    &__QRCode {
        width: 256px;
        height: 256px;
        margin-bottom: 25px;
        @media (max-width: 768px) {
            margin-bottom: 4.5%;
        }
    }
    &__checkConnectionButton {
        width: 220px;
        height: 64px;
        background: rgba(105, 78, 240, 1);
        transition-duration: 0.3s;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.94px;
        text-align: left;
        border-radius: 8px;
        @media (max-width: 768px) {
            width:200px;
            height:54px;
            min-height: 50px;
        }
    }
    &__checkConnectionButton:hover{
        background: rgb(118, 94, 238);
    }

    // @media (max-width: 768px) {
    //     width: 100%;
    //     min-height: 100%;
    //     overflow: scroll;
    //     border-radius: 0;
    //     padding: 14px;
    // }
}
