.strategy-editor-list {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  margin: 10px 12px;
  padding: 0px 14px;
  background-color: rgba(38, 35, 54, 1);
  min-height: 44px;
  max-height: 44px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  transition: background-color 0.2s;
  @media screen and (max-width: 768px) {
    margin-top: 0s;
    min-height: 50px;
    max-height: 50px;
  }


  &_no-wrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__back {
    width: 28px;
    aspect-ratio: 1 / 1;

    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 1;

    // background-color: white;

    transition: opacity 0.2s;
    fill: white;
    stroke: white;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  &__empty {
    color: white;
    font-size: 10px;
    line-height: 12px;
    transition: 0.2s;
  }

  &__create {
    flex-shrink: 0;

    outline: none;
    border: none;

    border-radius: 4px;
    background-color: #686382;
    width: 24px;
    height: 24px;
    max-width: 24px;
    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 12px;
    font-weight: 700;

    cursor: pointer;

    opacity: 1;

    transition: 0.2s;

    &_circle {
      width: 28px;
      padding: 0;
      aspect-ratio: 1 / 1;

      // border-radius: 100%;
    }

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }

  &-strategies {
    flex-grow: 1;

    min-width: 10px;
    min-height: 40px;

    overflow-x: scroll;
    scrollbar-width: none;
    display: flex;
    align-items: center;
    gap: 5px;

    &::-webkit-scrollbar-thumb {
      background-color: #9747ff;
    }
    &__element {
      padding: 7px;

      border-radius: 4px;

      background-color: #00000066;

      font-size: 8px;

      transition: 0.2s;
      user-select: none;
      cursor: pointer;
      text-wrap: none;
      white-space: nowrap;
      display: flex;
      gap: 5px;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-around;
      min-width: 115px;
      background-color: #454154;
      margin-right: 10px;
      max-width: 160px;

      &:hover {
        background-color: #24222c;
      }
      &__text {
        max-width: 87%;
        overflow: hidden;
      }
      &__text::-webkit-scrollbar {
        display: none;
      }
      &__text::-webkit-scrollbar-thumb {
        height: 0px;
      }
      &__text ::-moz-scrollbar {
        display: none;
      }
      &__text ::-moz-scrollbar-thumb {
        height: 0px;
      }

      & img {
        height: 20px;
        opacity: 0;
      }
      &__text {
        max-width: 87%;
        overflow: scroll;
        scrollbar-width: none;
      }
      &__text::-webkit-scrollbar {
        display: none;
      }
      &__text::-webkit-scrollbar-thumb {
        height: 0px;
      }
      &__text ::-moz-scrollbar {
        display: none;
      }
      &__text ::-moz-scrollbar-thumb {
        height: 0px;
      }

      &_active {
        background-color: #2f2d3a;

        font-size: 12px;

        // &:hover {
        //   background-color: black;
        // }
        img {
          height: 20px;
          opacity: 100;
        }
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      &__element {
        font-size: 11px;
        &_active {
          font-size: 14px;
        }
      }
    }
  }
  &-strategies {
    scrollbar-width: none;
  }

  // &_disabled {
  //   background-color: var(--color-gray);
  // }

  // &_disabled &__empty {
  //   opacity: 0.5;
  // }

  // &_disabled &-strategies {
  //   opacity: .5;

  //   &__element:hover {
  //     background-color: #00000066;

  //     cursor: default;
  //   }
  // }
}
.strategy-editor-list-strategies__element:hover img {
  opacity: 100;
}
