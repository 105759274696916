.strategy-other-filters {
    width: 95%;
    display: flex;
    flex-direction: column;
    &__title {
        font-family: Inter;
        color: white;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 12px;
    }
    &__content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            margin-bottom: 55px;
        }
    }
    &__selector {
        width: 77%;
    }
}
