.container {
    width: 100vw;
    height: 100%;
    z-index: 1005;
    background: #0000008c;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    @media screen and (max-width: 768px) {
        height: 100%;
        max-height: 100%;
    }
    &__content {
        width: 650px;
        height: 100%;
        background: #130e2d;
        @media screen and (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        &_header {
            width: 100%;
            height: 8.3%;
            min-height: 8.3%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 12px;
            white-space: nowrap;
            &_light {
                background-color: #241b54;
            }
            @media screen and (max-width: 768px) {
                height: 80px;
                min-height: 80px;
            }
            &_text {
                font-family: Inter;
                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
                letter-spacing: 0px;
                text-align: left;
                display: flex;
            }
            &_icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
        &_main {
            display: flex;
            height: 91.7%;
            min-height: 91.7%;
            @media screen and (max-width: 768px) {
                flex: 1;
            }
            &_menu {
                display: flex;
                flex-direction: column;
                width: 230px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                &_item {
                    display: flex;
                    align-items: center;
                    width: 230px;
                    height: 48px;
                    min-height: 48px;
                    padding-left: 20px;
                    letter-spacing: 0px;
                    text-align: left;
                    cursor: pointer;
                    transition-duration: 0.2s;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;

                    img {
                        margin-right: 12px;
                    }
                    &:hover {
                        background: #9747ff;
                    }
                    &_disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                        &:hover {
                            background: transparent;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
            &_content {
                width: 420px;
                height: 100%;
                background-color: #241b54;
                padding: 18px 18px 24px 18px;
                overflow-x: scroll;
                // scrollbar-width: none;
                overflow-y: visible;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding: 24px 18px 50px 18px;
                }
                &_header {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.5);
                    margin-bottom: 30px;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    white-space: nowrap;
                }
                &_bigHeader {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    margin-bottom: 24px;
                }
                &_refresh {
                    width: 171px;
                    height: 44px;
                    min-height: 44px;
                    max-height: 44px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background: rgba(69, 65, 84, 1);
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    cursor: pointer;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
                &_pickerContainer {
                    width: 310px;
                    padding: 7px;
                    background-color: #130e2d;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 4px;
                }
                &_buttonContainer {
                    width: 310px;
                    height: 40px;
                    background-color: rgb(19, 14, 45);
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 10px;
                    &_button {
                        width: 92px;
                        height: 32px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #694ef0;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: white;
                    }
                }
            }
        }
    }
}
.settings {
    padding: 15px;
    cursor: pointer;
    position: absolute;
    top: -15px;
}
.flexbox-fix {
    border: none !important;
}
.propertyMainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.propertyContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    text-align: left;

    letter-spacing: 0px;
    text-align: left;
    height: 36px;
    max-height: 36px;
    min-height: 36px;
    width: 100%;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.5);
    &_inputContainer {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        img {
            cursor: pointer;
        }
        &_input {
            width: 253px;
            height: 54px;
            border-radius: 12px;
            background-color: transparent;
            border: 1px solid rgba(163, 163, 163, 1);
            outline: none;
            padding: 18px;
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: left;
            color: white;
            @media screen and (max-width: 768px) {
                width: 61%;
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;
            }
        }
    }
    &_title {
        margin-top: 8px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: right;
        width: 100%;
        color: rgba(255, 255, 255, 0.74);
    }
    &_dateContainer {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    &_date {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    &_socialMediaContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 24px;
    }
    &_saveButton {
        width: 90px;
        height: 32px;
        background-color: rgba(167, 0, 246, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: white;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        margin-top: 18px;
        margin-bottom: 45px;
        img {
            margin-right: 4px;
        }
        &_disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 12px;
        }
    }
}
.fotoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 768px) {
        justify-content: center;
        margin-bottom: 24px;
    }
    &_foto {
        width: 100px;
        height: 100px;
        min-height: 100px;
        min-width: 100px;
        max-height: 100px;
        max-width: 100px;
        border-radius: 23px;
        overflow: hidden;
        margin-right: 24px;
        img {
            width: 100px;
            height: 100px;
            min-height: 100px;
            min-width: 100px;
            max-height: 100px;
            max-width: 100px;
        }
    }
    &_buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        &_uploadFoto {
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            text-align: left;
            color: white;
            span {
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
                line-height: 14.52px;
                text-align: left;
                color: #a7a7a7;
            }
        }
        &_deleteFoto {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: rgba(246, 7, 7, 1);
        }
    }
}
