.g-input {
  position: relative;
  width: 100%;
  &_error{
    color:red;
    font-size: 14px;
    position: absolute;
    bottom: -25px;
    left: 10px;
    height:20px;
  }

  & input {
    width: 100%;
    padding: 22px 18px;

    outline: none;
    border: none;
    border-radius: 4px;

    background-color: #262336;

    font-size: 14px;
    font-weight: 300;
    color: #fff;

    &::placeholder {
      color: #CBCBCB;
      font-size: 14px;
      font-weight: 300;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  &__eye {
    position: absolute;
    right: 18px;
    top: 50%;
    translate: 0 -50%;

    cursor: pointer;
  }
}