.chartInfoSelector {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2vh;
}

.chartInfoSelectorTicker {
  display: flex;
  align-items: center;
  gap: 1vh;
  cursor: pointer;
  min-width: 170px;
  user-select: none;
}

.chartInfoSelectorTicker img {
  height: 2.4vh;
}

.chartInfoSelectorTimeframe {
  height: 100%;
  cursor: pointer;
}
.chartType {
  display: flex;
  line-break: normal;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  min-width: 82px;
}
