.strategy-editor-empty {
  width: 100%;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    max-width: 96px;
    width: 100%;

    margin: 32px 0 24px 0;

    mix-blend-mode: difference;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    text-align: center;
    margin-bottom: 15px;
  }

  &__description {
    width: 62%;
    margin-bottom: 45px;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: center;
    color: white;

    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  &-actions {
    min-width: 140px;
    min-height: 44px;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}
