.demo-balance {
  width: 199px;
  height: 40px;
  background: rgba(38, 35, 54, 1);

  border-radius: 6px;
  padding-left: 12px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  color:white;
}
.demo-balance-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color:white;
}
.demo-balance-subtitle {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  color: rgba(120, 120, 120, 1);
}
