.date-dropdown {
    display: flex;
    flex-direction: column;
    height: 24px;
    max-height: 24px;
    cursor: pointer;
    transition-duration: 0.2s;
    user-select: none;
    width: 72px;
    &__selected {
        z-index: 5;
        min-height: 24px;
        width: 100%;
        background: rgba(105, 78, 240, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding-left: 14px;
        border-radius: 3px;
        position: relative;
        margin-bottom: 5px;

        &__numbersOnly {
            height: 33px;
            max-height: 33px;
            min-height: 33px;
        }
        &_name {
            font-family: Inter;
            font-size: 10px;
            font-weight: 700;
            line-height: 12.1px;
            text-align: left;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &_desc {
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0px;
            text-align: left;
        }
        &_arrow {
            top: 50%;
            right: 5px;
            position: absolute;
            transform: translateY(-50%);
            transition-duration: 0.3s;
        }
        &_arrowOpened {
            transform: translateY(-50%) rotate(180deg);
        }
    }
    &__itemsContainer {
        max-height: 180px;
        min-height: 180px;
        z-index: 10;
        overflow: scroll;

        border-radius: 3px;
        background: rgba(105, 78, 240, 1);
    }
    &__item {
        z-index: 6;
        min-height: 24px;
        width: 100%;
        background: rgba(105, 78, 240, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
        padding-left: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &__numbersOnly {
            height: 33px;
            max-height: 33px;
            min-height: 33px;
        }
        &_name {
            font-family: Inter;
            font-size: 10px;
            font-weight: 700;
            line-height: 12.1px;
            text-align: left;
            letter-spacing: 0px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 90%;
        }
        &_desc {
            font-family: Inter;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0px;
            text-align: left;
        }
    }
    &__item:hover {
        background: rgb(133, 114, 228);

    }
}
