.g-button {
  position: relative;
  flex-basis: 100%;

  padding: 12px;

  outline: none;
  border: none;
  border-radius: 4px;

  background: transparent;

  cursor: pointer;

  transition: 0.2s;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: center;
  min-height: 44px;
  max-width: 140px;

  &_tiny {
    padding: 5px 12px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
