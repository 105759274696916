.non-strategy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url('../../../../../assets/images/nonestrategy-bg.png') center center / cover #130E2D;
  &__title {
    font-size: 24px;
    font-weight: 900;
    margin: 0 auto 17px;
    color: #fff;
  }
  &__descr {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    max-width: 328px;
    margin: 0 auto;
    text-align: center;
  }
  &__bg {
    position: absolute;
    top: 18px;
    left: 14px;
  }
}