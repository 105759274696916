.nameContainer {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;

    img {
        margin-right: 10px;
    }
}
.dropDownContainer {
    max-width: 100px;
    width: 100px;
    max-height: 48px;
    div {
        font-weight: 500 !important;
    }
}
