.addButton {
    padding: 0 16px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #515151;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: auto;
    color: #fff;
    img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
    @media (max-width: 768px) {
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        img {
            margin-right: 8px;
        }
    }
}
