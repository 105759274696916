@font-face {
  font-family: "Michroma";
  src: url("./assets/fonts/Michroma-Regular.ttf");
}

:root {
  --color-blue: #1666b9;
  --color-blue-hover: #288df5;
  --color-blue-darker: #2867a8;
  --color-gray: #bcbcbc;
  --color-gray-hover: #d3d3d3;
  --color-green: #68bd85;
  --color-green-contrast: #71ea6e;
  --color-red: #d85c5c;
  --color-red-contrast: #b22424;
  --color-white: #f6f6f6;
  --color-placeholder: #838383;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
html {
  height: 100%;
  scrollbar-width: none;
  background: rgba(19, 14, 45, 1);
}
body {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden !important;
  height: 100%;
}
#root {
  height: 100%;
  //@media (max-width: 768px) {
  //  height: unset;
  //}
}
button,
input {
  font-family: "Nunito", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  border: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
}

.svg-icon {
  width: 1rem;
  height: 1rem;
  color: #000;
  cursor: pointer;
}

p {
  padding: 0;
}
// html {
//   scrollbar-width: thin;
//   scrollbar-color: #9747ff transparent;
// }

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #9747ff;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9747ff;
}

.invisible {
  visibility: hidden;
}

.link {
  position: relative;

  color: var(--color-blue);
  font-weight: 500;

  transition: 0.2s;

  cursor: pointer;

  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background-color: var(--color-blue);

    transition: 0.2s;
  }

  &:hover {
    color: var(--color-blue-hover);

    &::after {
      background-color: var(--color-blue-hover);
    }
  }
}

.link_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.violet-bg {
  background-color: #694ef0 !important;
}
.purple-bg {
  background-color: rgba(132, 119, 197, 1) !important;
}
.violet-bg_hover:hover {
  background-color: #543fc0 !important;
}
.gray-bg {
  background-color: #443f5e !important;
}
.gray-bg_hover:hover {
  background-color: #36324a !important;
}
.white-bg {
  background-color: white !important;
}

.white-border {
  border: 1px solid white;
}

.blue-bg {
  background-color: var(--color-blue-hover) !important;
}

.blue-bg_hover:hover {
  background-color: var(--color-blue) !important;
}

.blue-border {
  border: 1px solid !important;
  border-color: var(--color-blue) !important;
}

.blue-border_hover:hover {
  border-color: var(--color-blue-hover) !important;
}

.red-bg {
  background-color: var(--color-red) !important;
}

.red-bg_contrast {
  background-color: var(--color-red-contrast) !important;
}

.red-border {
  border: 1px solid !important;
  border-color: var(--color-red) !important;
}

.red-border_contrast {
  border-color: var(--color-red-contrast) !important;
}

.green-bg {
  background-color: var(--color-green) !important;
}

.green-bg_contrast {
  background-color: var(--color-green-contrast) !important;
}

.green-border {
  border: 1px solid !important;
  border-color: var(--color-green) !important;
}

.green-border_contrast {
  border-color: var(--color-green-contrast) !important;
}
