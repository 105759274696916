.strategy-selector {
  display: flex;
  width: 100px;
  height: 44px;
  line-height: 44px;
  gap: 5px;
  justify-content: flex-end;

  &__select {
    width: 85%;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #262336 !important;
    &-select {
      background-color: #262336 !important;
      //background-image: url("../../../../../../assets/HomeLog/select-expand_down.svg");
      //background-repeat: no-repeat;
      //background-position: right 0.7rem top 50%;
      //background-size: 1.4rem auto;

      outline: none;
      border: none;
      border-radius: 6px;
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      height: 100%;

      cursor: pointer;

      appearance: none;
    }
  }

  &__settings {
    background-color: #262336;
    width: 10%;
    aspect-ratio: 1/1;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: 2%;

    & img {
      height: 50%;
    }
  }
}
