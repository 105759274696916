.container {
    width: 100vw;
    height: 100%;
    z-index: 1005;
    background: #0000008c;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    @media screen and (max-width: 768px) {
        height:100%;
        max-height: 100%;
    }
    &__content {
        width: 650px;
        height: 100%;
        background: #130e2d;
        @media screen and (max-width: 768px) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        &_header {
            width: 100%;
            height: 8.3%;
            min-height: 8.3%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 12px;
            white-space: nowrap;
            @media screen and (max-width: 768px) {
                height: 80px;
                min-height: 80px;
            }
            &_text {
                font-family: Inter;
                font-size: 24px;
                font-weight: 700;
                line-height: 29px;
                letter-spacing: 0px;
                text-align: left;
                display: flex;
            }
            &_icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
        &_main {
            display: flex;
            height: 84.2%;
            min-height: 84.2%;
            @media screen and (max-width: 768px) {
                flex: 1;
            }
            &_menu {
                display: flex;
                flex-direction: column;
                width: 280px;
                @media screen and (max-width: 768px) {
                    width:100%;
                }
                &_item {
                    display: flex;
                    align-items: center;
                    width: 280px;
                    height: 48px;
                    min-height: 48px;
                    padding-left: 20px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    cursor: pointer;
                    transition-duration: 0.3s;
                    img {
                        margin-right: 12px;
                    }
                    @media screen and (max-width: 768px) {
                        width:100%;
                    }
                }
                &_item:hover {
                    background: #9747ff;
                }
            }
            &_content {
                width: 370px;
                height: 100%;
                background-color: #241b54;
                padding: 24px 18px 24px 18px;
                overflow-x: scroll;
                // scrollbar-width: none;
                overflow-y: visible;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding: 24px 18px 50px 18px;
                }
                &_header {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: rgba(255, 255, 255, 0.5);
                    margin-bottom: 30px;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    white-space: nowrap;
                }
                &_bigHeader {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    margin-bottom: 24px;
                }
                &_refresh {
                    width: 171px;
                    height: 44px;
                    min-height: 44px;
                    max-height: 44px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background: rgba(69, 65, 84, 1);
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    cursor: pointer;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
                &_pickerContainer {
                    width: 310px;
                    padding: 7px;
                    background-color: #130e2d;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 4px;
                }
                &_buttonContainer {
                    width: 310px;
                    height: 40px;
                    background-color: rgb(19, 14, 45);
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 10px;
                    &_button {
                        width: 92px;
                        height: 32px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #694ef0;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: white;
                    }
                }
            }
        }
        &_footer {
            width: 100%;
            height: 7.5%;
            min-height: 7.5%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 12px;
            @media screen and (max-width: 768px) {
                   display: none;
            }
            &_dropdown {
                width: 180px;
                height: 44px;
            }
            &_buttons {
                display: flex;
                align-items: center;
                height: 100%;
                &_back {
                    width: 171px;
                    height: 88%;
                    max-height: 44px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background: #262336;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    cursor: pointer;
                    margin-right: 4px;
                }
                &_back:hover {
                    opacity: 0.8;
                }

                &_create {
                    width: 171px;
                    height: 88%;
                    max-height: 44px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background-color: #9747ff;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    cursor: pointer;
                }
                &_create:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}
.sketch-picker {
    background-color: rgba(19, 14, 45, 1) !important;
    display: flex !important;
    flex-direction: column !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    input {
        background: rgba(38, 35, 54, 1) !important;
        color: white !important;
        box-shadow: none !important;
        width: 80px !important;
        height: 30px !important;
        border-radius: 4px !important;
        font-family: Inter !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 15px !important;
        letter-spacing: 0px !important;
        text-align: left !important;
        padding: 4px 4% 3px 4% !important;
    }

    input:focus {
        outline: none !important;
        border-color: none !important;
        box-shadow: none !important;
    }
    label {
        display: none !important;
    }
}
.flexbox-fix {
    border: none !important;
}
.propertyMainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.propertyContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    height: 36px;
    max-height: 36px;
    min-height: 36px;
    width: 100%;
    margin-bottom: 10px;
    &__colorsContainer {
        width: 36.5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__thicknessColorContainer {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__numberDropdown {
        width: 80px;
        max-width: 80px;
    }
    &__candleTypeDropDown {
        width: 170px;
        max-width: 170px;
    }
    &__dropDown {
        width: 120px;
        max-width: 120px;
    }
}
