.strategyTypeTitle {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    width: 90%;
    margin: 6px 12px;
    // padding: 10px 14px 10px 14px;
    color: white;
    margin-bottom: 10px;
}
.strategyTypeToggleButton {
    width: 95%;
    min-height: 54px;
    height: 54px;
    background-color: #262336;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    margin: 6px 12px;
    border-radius: 6px;
    padding: 10px 14px 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition-duration: 0.3s;
    img {
        transition-duration: 0.3s;
    }
    &__opened {
        background-color: #694ef0;
        img {
            transform: rotate(180deg);
        }
    }
    &__disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.strategyTypeCreateButton {
    width: 100%;
    min-height: 54px;
    height: 54px;
    background-color: #262336;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    margin: 6px 0px;
    border-radius: 6px;
    padding: 10px 14px 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition-duration: 0.3s;
    img {
        transition-duration: 0.3s;
    }
    &__opened {
        background-color: #694ef0;
        img {
            transform: rotate(180deg);
        }
    }
    &__disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
.strategy-element {
    width: 100%;
    height: 54px;
    padding: 10px 14px 10px 14px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_info {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 3px;
        &_name {
            font-family: Inter;
            font-size: 14px;
            font-weight: 700;
            line-height: 16.94px;
            text-align: left;
            color: white;
        }
        &_status {
            font-family: Inter;
            font-size: 10px;
            font-weight: 500;
            line-height: 12.1px;
            text-align: left;
            color: #b8b8b8;
            cursor: pointer;
            span {
                color: white;
                font-family: Inter;
                font-size: 10px;
                font-weight: 700;
                line-height: 12.1px;
                text-align: left;
            }
        }
    }
    &_buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 11px;
        //styleName: Button text/Button Active;

        &_copy {
            font-family: Inter;
            font-size: 10px;
            font-weight: 600;
            line-height: 12.1px;
            text-align: left;
            padding: 8px 12px;
            background-color: #694ef0;
            border-radius: 4px;
            color: white;
        }
        &_preview {
            font-family: Inter;
            font-size: 10px;
            font-weight: 600;
            line-height: 12.1px;
            text-align: left;
            padding: 8px 12px;
            border-radius: 4px;
            color: white;
            background: #9f4ef0;
        }
    }
}
