.mainContainer {
  width: 28px;
  height: 28px;
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
  min-height: 28px;
  position: relative;
}
.name {
  position: absolute;
  position: absolute;
  left: -10px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.5);
  top: -20px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
}
.pickerContainer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1006;
}
.picker {
  width: 310px;
  padding: 10px;
  position: absolute;
  background-color: #130e2d;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  top: 5px;
  left: 5px;
  @media screen and (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.buttonContainer {
  width: 310px;
  height: 40px;
  background-color: rgb(19, 14, 45);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  &_button {
    width: 92px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #694ef0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
  }
}
.colorContainer {
  width: 28px;
  height: 28px;
  border: 1px solid white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &__color {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
}
