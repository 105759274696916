input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0; /* Optional: Remove default margin */
}
.input {
    margin-left: auto;
    width: 100px;
    max-width: 100px;
    height: 48px;
    max-height: 48px;
    padding: 0 10px;
    outline: none;
    border: none;
    background: #262336;
    border-radius: 4px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
}
.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0; /* Optional: Remove default margin */
}
input[type="number"] {
    -moz-appearance: textfield;
}
.input-text-container {
    position: relative;
    margin-right: 30px;
}
@media screen and (max-width: 768px) {
    .input-text-container {
        position: relative;
        margin-right: 0;
    }   
}
input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}
.plusButton {
    height: 12px;
    width: 15px;
    max-width: 15px;
    max-height: 12px;
    min-width: 15px;
    min-height: 12px;
    position: absolute;
    top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 5px;
    img {
        width: 10px;
        height: 6px;
        min-height: 6px;
        min-width: 10px;
        max-height: 6px;
        max-width: 10px;
    }
}
.plus-mob {
    height: 35px;
    width: 35px;
    max-width: 35px;
    max-height: 35px;
    min-width: 25px;
    min-height: 22px;
    position: absolute;
    top: -13px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    right: -5px;
    padding-bottom: 5px;
    img {
        width: 20px;
        height: 16px;
        min-height: 16px;
        min-width: 20px;
        max-height: 16px;
        max-width: 20px;
    }
}
.minusButton {
    height: 12px;
    width: 15px;
    max-width: 15px;
    max-height: 12px;
    min-width: 15px;
    min-height: 12px;
    position: absolute;
    bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;
    transform: rotate(180deg);
    right: 5px;
    img {
        width: 10px;
        height: 6px;
        min-height: 6px;
        min-width: 10px;
        max-height: 6px;
        max-width: 10px;
    }
}
.minus-mob {
    height: 35px;
    width: 35px;
    max-width: 35px;
    max-height: 35px;
    min-width: 25px;
    min-height: 22px;
    position: absolute;
    bottom: -13px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transform: rotate(180deg);
    right: -5px;
    padding-bottom: 5px;
    img {
        width: 20px;
        height: 16px;
        min-height: 16px;
        min-width: 20px;
        max-height: 16px;
        max-width: 20px;
    }
}
.input-container {
    display: flex;
    align-items: center;
}
.input-title {
    position: absolute;
    margin-left: 5px;
    right: -20px;
}
