.chartMain {
  display: flex;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  flex: 1;
}
.chartWrapperFullScreen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
}
@media screen and (max-width: 768px) {
  .chartWrapper {
    padding-top: 5px;
  }
  /* .chartWrapperFullScreen {
    height: 100vh;
  } */
}

.chartHeader {
  width: 100%;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  /* padding-left: 5px; */
  /* overflow-x: scroll;
  overflow-y: hidden; */
  scrollbar-width: none;
  position: relative;
  /* border: 1px solid red; */
}
.chartHeaderCustomIndex {
  min-height: 76px !important;
}
.chartHeaderMerged {
  min-height: 95px !important;
}
.chartHeaderMergedCustomIndex {
  min-height: 119px !important;
}

.chartHeader::-webkit-scrollbar {
  height: 1px !important;
}

.chartHeaderInnerContainer {
  min-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  scrollbar-width: none !important;
  position: relative;
  /* border: 1px solid green; */
}
.fakeScrollMainContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.chartHeaderInnerContainerCustomIndex {
  min-height: 76px !important;
}
.chartHeaderInnerContainerMerged {
  min-height: 95px !important;
}
.chartHeaderInnerContainerMergedCustomIndex {
  min-height: 115px !important;
}

@media screen and (max-width: 768px) {
  .chartHeader {
    min-height: 40px;
    padding-right: 0px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
  .chartHeaderCustomIndex {
    min-height: 76px !important;
  }
  .chartHeaderMerged {
    min-height: 90px !important;
  }
  .chartHeaderMergedCustomIndex {
    min-height: 122px !important;
  }
  .chartHeaderInnerContainer {
    min-height: 40px;
  }
  .chartHeaderInnerContainerCustomIndex {
    min-height: 76px !important;
  }
  .chartHeaderInnerContainerMerged {
    min-height: 90px !important;
  }
  .chartHeaderInnerContainerMergedCustomIndex {
    min-height: 122px !important;
  }
}

.chartHeaderScrollContainer {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  background: linear-gradient(90deg, #2722ad 0%, #100e47 51.61%, #100e47 100%);
}
.chartHeaderScrollContainer::-webkit-scrollbar {
  display: none;
}
.chartHeaderScrollContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.chartHeaderScrollContainer::-webkit-scrollbar {
  background-color: transparent !important;
}

.chartHeaderScrollContainer::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
}
.chartHeaderScrollContainer ::-webkit-scrollbar-thumb {
  background-color: rgb(151, 71, 255);
}

.chartHeaderContent {
  height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5vw;
  padding-left: 10px;
  position: relative;
  scrollbar-width: none;
}
.chartHeaderContent::-webkit-scrollbar {
  display: none;
}
.chartHeaderContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.chartHeaderContent::-webkit-scrollbar {
  background-color: transparent !important;
}

.chartHeaderContent::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
}
.chartHeaderStartCustomContent {
  width: 100%;
  min-height: 32px;
  /* border-radius: 6px; */
  z-index: 3;
  display: flex;
  align-items: center;
  color: white;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}
.chartHeaderStartCustomContent::-webkit-scrollbar {
  display: none;
}
.chartHeaderStartCustomContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.chartHeaderStartCustomContent::-webkit-scrollbar {
  background-color: transparent !important;
}

.chartHeaderStartCustomContent::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
}
.mergedStrategiesHeaderList {
  width: 100%;
  min-height: 35px;
  left: 40px;
  z-index: 3;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: white;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  background-color: rgb(10, 8, 26);
}
.mergedStrategiesHeaderList::-webkit-scrollbar {
  display: none;
}
.mergedStrategiesHeaderList::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.mergedStrategiesHeaderList::-webkit-scrollbar {
  background-color: transparent !important;
}

.mergedStrategiesHeaderList::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
}
.mergedStrategiesHeaderList ::-webkit-scrollbar-thumb {
  background-color: rgb(151, 71, 255);
}

@media screen and (min-width: 768px) {
  .mergedStrategiesHeaderListItemCustomTicker {
    height: 35px !important;
  }
}
.fakeChartScrollBarNotCustomIndex {
  bottom: 5px;
}
@media screen and (max-width: 768px) {
  .fakeChartScrollBarNotCustomIndex {
    bottom: 0px;
  }
}
.mergedStrategiesHeaderListItem {
  height: 50px;
  width: 180px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  cursor: pointer;
  transition-duration: 0.05s;
  margin-right: 5px;
  border-bottom: 4px solid transparent;
}
@media screen and (max-width: 768px) {
  .mergedStrategiesHeaderList {
    padding-bottom: 0px;
    height: 50px;
    min-height: 50px;
    margin-bottom: 0px;
  }
  .mergedStrategiesHeaderListItem {
    height: 45px;
  }
}

.mergedStrategiesHeaderListItem:hover {
  background: linear-gradient(180deg, rgba(58, 0, 153, 0) 0%, rgba(97, 0, 255, 0.5) 94.29%);
  border-bottom: 4px solid rgba(87, 20, 173, 1);
}
.mergedStrategiesHeaderListItemSelected {
  background: linear-gradient(180deg, rgba(58, 0, 153, 0) 0%, rgba(97, 0, 255, 0.5) 94.29%);
  border-bottom: 4px solid rgba(87, 20, 173, 1);
}
.chartHeaderStartCustomContent > :first-child {
  border-radius: 6px 0 0 6px;
}
.chartHeaderStartCustomContent > :last-child {
  border-radius: 0 6px 6px 0;
}
.chartHeaderStartCustomContentItem {
  background: linear-gradient(180deg, #03296b 0%, rgba(11, 11, 30, 0.69) 80.78%);
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 12px;
  padding-right: 10px;
  padding-left: 10px;
  width: 195px;
  min-height: 32px;
}
.chartHeaderStartCustomContentItem span {
  font-size: 12px;
  font-weight: 700;
}
.tickerSelectorContentImage {
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
}
@media screen and (max-width: 768px) {
  .chartHeaderInnerContainer {
    width: unset;
  }
}

.chartSidebarIconWrapper {
  width: 52px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* top: 41px; */
  left: 0;
  z-index: 5;
}
.chartSidebarIconWrapperCustomIndex {
  /* top: 77px; */
}
.chartSidebarIconWrapperMerged {
  /* top: 96px; */
}
.chartSidebarIconWrapperMergedCustomIndex {
  /* top: 115px; */
}
@media screen and (max-width: 768px) {
  /* .chartSidebarIconWrapper {
    top: 46px;
    width: 40px;
    min-width: 40px;
    position: absolute;
    left: 3px;
    z-index: 5;
  }
  .chartSidebarIconWrapperCustomIndex {
    top: 82px;
  }
  .chartSidebarIconWrapperMerged {
    top: 96px;
  }
  .chartSidebarIconWrapperMergedCustomIndex {
    top: 128px;
  } */
  .chartSidebarIconWrapper {
    width: 43px;
  }
}

.asideIcon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  transition: all 0.2s;
  fill: inherit;
}

.asideIconActive {
  transform: rotate(-180deg);
}

.popupWindowWrapper {
  background-color: #fff;
  width: auto;
  position: absolute;
  display: none;
  z-index: 1000;
  box-shadow: 0 18px 34px 0 rgba(0, 0, 0, 0.64);
  max-height: 195px;
  overflow-y: auto;
}

.popupWindowWrapperShow {
  display: flex;
  flex-direction: column;
}

.chartSection {
  width: calc(100% - 52px);
}
@media screen and (max-width: 768px) {
  .chartSection {
    width: calc(100% - 43px);
  }
}

.chartSectionActive {
  width: 100%;
}

.chartAside {
  width: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ebedf1;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .chartAside {
    margin-left: 3px;
    width: 40px;
  }
}

.brLine {
  display: block;
  width: 100%;
  border-top: 1px solid #ebedf1;
}

.chartAsideActive {
  width: 0;
  border-right: none;
}

.asideList {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  align-items: center;
  margin-top: 40px;
}

.asideListItem {
  margin: 8px 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.asideIconWrapper {
  display: flex;
  align-items: center;
}

.asideIconWrapper:hover {
  background-color: rgba(22, 119, 255, 0.15);
}

.asideIconWrapperSelected svg {
  background-color: rgba(22, 119, 255, 0.15);
  fill: #1677ff;
  stroke: #1677ff;
}

.asideArrowWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 10px;
  opacity: 0;
  transition: all 0.2s;
  z-index: 10;
}

.asideArrowIcon {
  width: 4px;
  height: 6px;
  fill: #76808f;
  stroke: #76808f;
  transition: all 0.2s;
}

.asideArrowIconSelect {
  transform: rotate(180deg);
}

.asideArrowWrapper:hover {
  opacity: 1;
  background-color: rgba(22, 119, 255, 0.15);
}

.svg {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  transition: all 0.2s;
  color: inherit;
  fill: inherit;
  stroke: inherit;
}

.popUpItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
}

.popUpItem:hover {
  background-color: rgba(22, 119, 255, 0.15);
}

.popUpItemName {
  font-size: 12px;
  margin: 0 0 0 10px;
}

.rangeDatePicker {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .rangeDatePicker {
    padding-left: 20px;
  }
}

.separator {
  width: 11px;

  height: 1px;
  margin: 0 0 0 6px;
  background-color: white;
}

.datePickerWrapper {
  max-width: 95px;
  outline: none;
  border: none;
}

.separator + .datePickerWrapper {
  margin: 0 0 0 6px;
  outline: none;
  border: none;
}

.datePickerWrapper input {
  background-color: rgba(69, 65, 84, 1);
  outline: none;
  border: none;
  color: white;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.datePickerWrapper input::placeholder {
  color: white;
}

.pickerButtonButton {
  width: 100%;
  font-size: 13px !important;
  margin: 17px 0 0 0;
}

.chartRollRealtime {
  position: absolute;
  right: 100px;
  bottom: 50px;
  height: 3vh;
  line-height: 4vh;
  z-index: 10;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}
.openChart {
  position: absolute;
  right: 75px;
  top: 10px;
  height: 3vh;
  line-height: 4vh;
  z-index: 10;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  color: white;
}
.closeChart {
  position: absolute;
  right: 75px;
  top: 10px;
  height: 3vh;
  line-height: 4vh;
  z-index: 10;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  color: white;
}
@media screen and (max-width: 768px) {
  .openChart {
    height: 4vh;
    width: 4vh;
  }
  .openChart img {
    width: 100%;
    height: 100%;
  }
  .closeChart {
    height: 4vh;
    width: 4vh;
  }
  .closeChart img {
    width: 100%;
    height: 100%;
  }
}

.chartRollRealtime img {
  width: 100%;
  height: 100%;
}

.chartHeaderRightContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 14px;
  min-width: 350px;
  cursor: pointer;
}
.chartDynamicBacktest {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 170px;
}
.chartHeadertIconsContainer {
  display: flex;
  justify-content: space-between;
  min-width: 325px;
  margin-left: 20px;
}
@media screen and (max-width: 768px) {
  .chartHeadertIconsContainer {
    min-width: 100px;
    padding-right: 10px;
  }
  .chartHeaderRightContainer {
    min-width: 360px;
  }
  .chartDynamicBacktest {
    min-width: 170px;
    display: none;
  }
}
.chartHeadertIconsContainerPreview {
  min-width: 100px;
  margin-right: 20px;
}
.chartHeaderRightContainerPreview {
  min-width: unset;
}
.positionSwitcher {
  min-width: 66px;
  min-height: 24px;
  background-color: #454154;
  border-radius: 2px;
  display: flex;
}
.positionItem {
  width: 50%;
  min-height: 24px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.strategyBtn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background: rgba(105, 78, 240, 1);
  white-space: nowrap;
  padding: 0 12px;
  height: 32px;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #fff;
  position: absolute;
  bottom: 28px;
  z-index: 3;
  left: 47px;
  cursor: pointer;
}

.strategyBtn img {
  transform: rotate(90deg);
  margin-left: 8px;
}
.openStrategyBtn {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background: rgba(105, 78, 240, 1);
  white-space: nowrap;
  padding: 0 12px;
  height: 32px;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: #fff;
  position: absolute;
  bottom: -45px;
  z-index: 3;
  right: 0px;
  cursor: pointer;
}
.openStrategyBtn img {
  margin-left: 8px;
}

.fakeScrollBarContainer {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 95%;
  height: 2px;
  background: rgba(28, 22, 61, 1);
  /* display: none; */
  border-radius: 6px;
  z-index: 2;
}
.fakeChartScrollBar {
  position: absolute;
  bottom: 4px;
  left: 0;
  /* width: 33.33%;
  max-width: 33.33% !important; */
  height: 2px;
  background: #9747ff;
  /* display: none; */
  border-radius: 6px;
  z-index: 3;
}
.fakeChartScrollBar2 {
  position: absolute;
  bottom: 0px;
  left: 0;
  /* width: 33.33%;
  max-width: 33.33% !important; */
  height: 2px;
  background: #9747ff;
  /* display: none; */
  border-radius: 6px;
  z-index: 3;
}
@media (max-width: 768px) {
  .fakeChartScrollBar {
    display: block;
    bottom: 0px;
  }
  .fakeChartScrollBarContainer {
    display: block;
  }
}
