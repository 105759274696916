.addAccountPopUpContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    background: rgba(0, 0, 0, 0.4);
}
.addAccountPopUp {
    width: 66%;
    max-width: 955px;
    max-height: 550px;
    min-height: 47%;
    border-radius: 12px;
    padding: 54px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(23, 14, 50, 1);
    @media (max-width: 768px) {
        width: 100%;
        min-height: 100%;
        overflow: scroll;
        border-radius: 0;
        padding: 14px;
    }
    &__closeButton {
        position: absolute;
        top: 54px;
        right: 44px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-image: url("../../../../assets/images/MenuPage/CloseIcon.svg");
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-content: center;
        @media (max-width: 768px) {
            width: 24px;
            height: 24px;
            right: 12px;
            top: 18px;
        }
    }
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 48px;
        white-space: nowrap;
        width: 60%;
        color: #fff;
        @media (max-width: 1290px) {
            flex-direction: column;
        }
        @media (max-width: 768px) {
            margin-top: 20px;
            justify-content: space-between;
            margin-bottom: 23px;
        }
        .dropdownContainer {
            margin-left: 32px;
            background: linear-gradient(94.01deg, #1E0E4D -23.69%, #462B98 125.95%);
            border-radius: 4px;
            @media (max-width: 1290px) {
                display: block;
                margin: 20px 0 0;
            }
            @media (max-width: 786px) {
                margin-top: 10px;
            }
            &__dropdown_opened {
                background: inherit;
            }
            &__dropdown {
                &:hover {
                    background: inherit;
                }
            }
        }
    }
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    input {
        width: 470px;
        height: 54px;
        border-radius: 8px;
        background: rgba(38, 35, 54, 1);
        margin: 9px;
        outline: none;
        border: none;
        padding-left: 18px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        color: #fff;
        line-height: normal;
        @media (max-width: 768px) {
            width: 82%;
            height: 48px;
            border-radius: 6px;
            padding-left: 20px;
        }
    }
    input::placeholder {
        color: rgba(203, 203, 203, 1);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    &__errorInput {
        border: 1px solid #ae5858 !important;
    }
    &__error {
        width: 470px;
        color: #ae5858;
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        @media (max-width: 768px) {
            width: 82%;
        }
    }
    &__connectButton {
        width: 187px;
        height: 45px;
        margin-top: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: rgba(151, 71, 255, 1);
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: opacity .2s ease;
        &:hover {
            opacity: .8;
        }
        @media (max-width: 768px) {
            width: 140px;
            height: 44px;
            font-size: 10px;
            margin-top: 15px;
        }
    }
}
