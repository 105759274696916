.root:hover {
  cursor: pointer;
}

.primaryDark,
.primaryLight {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.primaryLight {
  gap: 4px;
  padding: 6px 14px;

  background-color: #fff;
  border-radius: 4px;
  color: black;
  font-size: 6px;
  line-height: 8px;
  max-lines: 1;
}

.primaryDark {
  gap: 8px;
  padding: 8px 14px;
  border-radius: 8px;
  background-color: rgba(151, 71, 255, 1);
  color: #fff;
  font-size: 8px;
  line-height: 11px;
}

.root:disabled {
  background-color: #5f5f5f;
  color: #777;
  cursor: default;
}
