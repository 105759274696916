.accountDropdownContainer {
    width: 100%;
    height: 24px;
    min-height: 24px;
    position: relative;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        margin-bottom: 12px;
    }
    &__accountDropdown {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 24px;
        transition-duration: 0.3s;
        z-index: 3;
        overflow: hidden;
        border-radius: 8px;
        transition-duration: 0.3s;
        &_opened {
            height: unset;
            box-shadow: 2px 2px 15px 0 #00000026;
        }
        &:hover {
            //background-color: #d6d6d6;
        }
    }
    &__dropdownItem {
        min-width: 228px;
        min-height: 24px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        transition-duration: 0.3s;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
    &__dropdownItem:hover {
        //background-color: #f3f3f3;
    }
    &__selectedAccount {
        position: relative;
        img {
            position: absolute;
            top: 1px;
            bottom: 0;
            right: 10px;
            width: 24px;
            height: 24px;
            transition-duration: 0.3s;
            transform-origin: center;
            margin: 0 18px;
        }
        &_active {
            img {
                transform: rotate(180deg);
            }
        }
    }

    &__accountDropdown_opened {
        overflow: visible;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: calc(100% + 20px);
            left: 0;
            top: -10px;
            background: #170E32;
            z-index: -1;
            border-radius: 8px;
        }
    }
}
