.tg-bot-alerts {
    width: 100%;
    height: 94%;
    padding: 12px 3% 0 3%;
    margin: -12px 0 0 0;
    background: #130e2d;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 12;
    // border: 1px solid white;
}
.tg-bot-alerts-header {
    width: 100%;
    display: flex;
    &__back {
        min-width: 68px;
        min-height: 28px;
        background: #694ef0;
        border-radius: 4px;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        margin-bottom: 12px;
    }
}
.tg-bot-title {
    width: 100%;
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    margin-bottom: 10px;
}

.tg-bot-nav-item {
    width: 100%;
    height: 84px;
    background: rgba(68, 63, 94, 0.5);
    padding: 24px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin-bottom: 10px;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    &_name {
        display: flex;
        align-items: center;
        &_disabled {
            opacity: 0.5;
        }
    }
    &_imgApiTrade {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        background-image: url(../../../../../../../assets/icons/ApiTradingIcon.svg);
        background-size: 100%;
        background-repeat: no-repeat;
    }
    &_imgTgAlert {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        background-image: url(../../../../../../../assets/icons/TgAlertsIcon.svg);
        background-size: 100%;
        background-repeat: no-repeat;
    }
    &_imgCustomAlert {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        background-image: url(../../../../../../../assets/icons/CustomAlertsIcon.svg);
        background-size: 100%;
        background-repeat: no-repeat;
    }
    &_imgAiOptimization {
        margin-right: 12px;
        width: 24px;
        height: 24px;
        background-image: url(../../../../../../../assets/icons/AiOptimizationIcon.svg);
        background-size: 100%;
        background-repeat: no-repeat;
    }
}
.tg-bot-nav-item img {
    margin-right: 12px;
}
.tg-bot-alerts-not-connected {
    width: 85%;
    margin-top: 118px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 300px;
    img {
        width: 84px;
        height: 84px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 40px;
        img {
            width: 70px;
            height: 70px;
        }
    }
    &__text {
        font-family: Nunito;
        font-size: 24px;
        font-weight: 800;
        line-height: 32.74px;
        text-align: center;
        margin-top: 26px;
        margin-bottom: 13px;
        padding: 0 32px;
    }
    &__title {
        font-family: Nunito;
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        text-align: center;
        padding: 0 32px;
    }
    &__button {
        margin-top: 30px;
        width: 100%;
        min-width: 200px;
        height: 44px;
        border-radius: 4px;
        background: rgba(151, 71, 255, 1);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        line-height: 14.52px;
        text-align: left;
        transition-duration: 0.3s;
    }
    &__button:hover {
        opacity: 0.8;
    }
}
.tg-bot-alerts-text {
    width: 100%;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
}
.tg-bot-alerts-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    margin-top: 38px;
}
