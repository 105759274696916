.resizeHandleOuter {
  flex: 0 0 2em;
  position: relative;
  outline: none;

  --background-color: transparent;
  --color-solid-resize-bar-handle: rgba(255, 255, 255, 0.2);
}

.resizeHandleOuter[data-resize-handle-active] {
  --background-color: var(--color-solid-resize-bar-handle);
}

.resizeHandleInner {
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  border-radius: 1px;
  background-color: var(--background-color);
  transition: background-color 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  min-height: 14px;
}

.icon {
  width: 12px;
  height: 12px;
  position: absolute;
  left: calc(50% - 6px);
  top: calc(50% - 6px);
}
