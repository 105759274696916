.dropdownContainer {
    width: 100%;
    max-width: 228px;
    height: 54px;
    position: relative;
    @media (max-width: 768px) {
        max-width: 180px;
    }

    &__dropdown {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 54px;
        overflow: hidden;
        transition-duration: 0.3s;
        z-index: 2;
        border-radius: 8px;
        &__item {
            min-width: 228px;
            min-height: 54px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.3s;
            @media (max-width: 768px) {
                min-width: 180px;
                max-width: 180px;
            }
        }
        &__selected {
            min-width: 228px;
            min-height: 54px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            transition-duration: 0.3s;
            position: relative;
            cursor: pointer;
            user-select: none;
            &__icon {
                position: absolute;
                top: 15px;
                bottom: 0;
                left: 10px;
                width: 24px;
                height: 24px;
                transition-duration: 0.3s;
                transform-origin: center;
                margin: 0 18px;
                @media (max-width: 768px) {
                    margin-left: 0;
                }
            }
            &__arrow {
                position: absolute;
                top: 15px;
                bottom: 0;
                right: 10px;
                width: 24px;
                height: 24px;
                transition-duration: 0.3s;
                transform-origin: center;
                margin: 0 18px;
                @media (max-width: 768px) {
                    margin-right: 0 !important;
                }
            }
            @media (max-width: 768px) {
                min-width: 180px;
                max-width: 180px;
            }
            &_active &__arrow {
                transform: rotate(180deg);
            }
        }
        &_opened {
            height: unset;
            background-color: white;
            box-shadow: 2px 2px 15px 0 #00000026;
        }
        &:hover {
            background-color: #d6d6d6;
        }
    }

    &__platformIcon {
        margin-right: 10px;
    }
}
