.entrySize {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(19, 14, 45, 1);
  left: 0;
  top: 0;
  padding: 12px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    cursor: pointer;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    text-align: left;
    color: white;
    margin-bottom: 28px;
    width: 100%;

    &__back {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      margin-right: 20px;
      width: 65px;
      padding-right: 5px;
      cursor: pointer;

      &_img {
        width: 24px;
        height: 24px;
        background-image: url(../../../../../../../../../assets/images/Strategy/BackArrow.svg);
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    margin-bottom: 35px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    color: white;
    padding: 15px;
  }
  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    height: 54px;
    text-align: left;
  }
  &_title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: rgba(188, 188, 188, 1);
  }
  &_input {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: nowrap;
    @media screen {
      padding-right: 6px;
      gap: 13px;
    }
  }
  &_switcher {
    display: flex;
    align-items: center;
    background: rgba(38, 35, 54, 1);
    width: 200px;
    border-radius: 3px;
    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 30px;
      border-radius: 3px;
      font-family: Inter;
      font-size: 10px;
      font-weight: 700;
      line-height: 12.1px;
      text-align: center;
      cursor: pointer;
      color: white;
    }
  }
  &_settingItem {
    width: 100%;
    min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    margin-bottom: 12px;
  }
  &_settingHeader {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
    color: rgba(154, 154, 154, 1);
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height:34px;
    margin:12px 0px;
  }
}
