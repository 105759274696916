.strategy-name {
  padding: 4px 0;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262336;
  width: 95%;
  min-height: 54px;
  height: 54px;
  border-radius: 6px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  position: relative;
  &_buttonsContainer {
    min-width: 90px;
    height: 32px;
    @media screen and (max-width: 768px) {
      height:24px;
    }
  }
  &_editButton {
    width: 80px;
    height: 32px;
    border: 1px solid rgba(167, 0, 246, 1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    img {
      margin-right: 4px;
    }
    @media screen and (max-width: 768px) {
      position: static;
      width: 60px;
      height: 24px;
      font-size: 12px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  &_saveButton {
    width: 90px;
    height: 32px;
    background-color: rgba(167, 0, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: absolute;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    img {
      margin-right: 4px;
    }
    @media screen and (max-width: 768px) {
      position: static;
      width: 60px;
      height: 24px;
      font-size: 12px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  & input {
    outline: none;
    border: none;
    width: calc(100% - 100px);
    height: 100%;
    background-color: #262336;
    padding-left: 15px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }

  // & button {
  //   outline: none;
  //   border: none;
  //   height: 100%;
  //   background: none;
  //   aspect-ratio: 1/1;
  //   cursor: pointer;
  //   margin-left: auto;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   & img {
  //     height: 20px;
  //     width: 20px;
  //   }
  // }
}
.strategy-name-error {
  border: 1px solid red;
}
.strategy-name-error-message {
  position: absolute;
  bottom: -20px;
  color: red;
  font-size: 12px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  font-family: Inter;
}
