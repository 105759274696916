.strategy-editor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;

  padding: 22px 14px;
  background: linear-gradient(180deg, #413DAC 0%, #130E2D 100%);

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &__title {
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    color: white;
    font-family: "Inter", sans-serif;
  }

  &-controls {
    display: flex;
    align-items: center;
    gap: 10px;

    &-misc {
      padding: 5px 10px;

      cursor: pointer;

      &__dots {
        position: relative;

        width: 4px;
        aspect-ratio: 1 / 1;

        border-radius: 50%;

        background-color: var(--color-gray);

        transition: 0.2s;

        &::after,
        &::before {
          content: "";

          position: absolute;
          top: 50%;
          translate: 0 -50%;

          width: 100%;
          aspect-ratio: 1 / 1;

          border-radius: 50%;

          background-color: var(--color-gray);

          transition: 0.2s;
        }

        &::after {
          left: calc(-100% - 3px);
        }

        &::before {
          right: calc(-100% - 3px);
        }
      }

      &:hover &__dots,
      &:hover &__dots::after,
      &:hover &__dots::before {
        background-color: var(--color-gray-hover);
      }
    }
  }
}
.strategy-editor-header-controls-close {
  cursor: pointer;
}
