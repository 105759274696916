.overlayBackground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.spinnerLoader {
  height: auto;
  animation: 0.934579s linear 0s infinite normal forwards running loader;
}
.overlayBackgroundFullSizeChart{
  z-index: 1003;
  position: fixed;
}

@keyframes loader {
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
