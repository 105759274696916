.pageContainer {
    width: 100vw;
    background: url(../../assets/images/MenuPage/MenuDesktopBackground.webp) center center / cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100%;
    padding-bottom: 24px;
    font-family: "Inter", sans-serif;
    @media (max-width: 768px) {
        padding: 32px 18px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    .button {
    }
    &__logo {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 64px auto;
        img {
            width: 119px;
        }
        @media (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 40px;
        }
    }
    &__logoName {
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        color: black;
        margin-bottom: 25px;
        width: 100%;
        text-align: center;
        @media (max-width: 768px) {
            text-align: left;
            font-size: 2.8vw;
            line-height: 2.8vw;
            margin-bottom: 25px;
        }
    }
    &__accountDropdownContainer {
        width: 100%;
        height: 54px;
        position: relative;
        margin-bottom: 30px;
    }
    &__accountDropdown {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 54px;
        overflow: hidden;
        transition-duration: 0.3s;
        z-index: 2;
        border-radius: 8px;
        @media (max-width: 768px) {
        }
        div {
            min-width: 228px;
            min-height: 54px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            transition-duration: 0.3s;
        }
        div:hover {
            background-color: #f3f3f3;
        }
    }
    &__accountDropdownOpened {
        height: unset;
        box-shadow: 2px 2px 15px 0 #00000026;
    }
    &__menu {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 7px;
        @media (max-width: 768px) {
            margin-bottom: 0px;
            align-items: flex-end;
        }
    }
    &__menuItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 268px;
        height: 64px;
        border-radius: 8px;
        margin: 7px;
        font-size: 16px;
        padding-left: 64px;
        padding-right: 10px;
        font-style: normal;
        font-weight: 500;
        transition-duration: 0.3s;
        background: linear-gradient(to right, rgba(23, 14, 50, 1), rgba(70, 43, 152, 1));
        color: #fff;
        position: relative;
        &:hover {
            opacity: 0.8;
        }
        &_disabled {
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
                opacity: 0.5;
            }
        }
        &_tgBotIcon {
            width: 38px !important;
            @media screen and (max-width: 768px) {
                width: 25px !important;
            }
        }
        img {
            margin-right: 14px;
            width: 44px;
            height: 44px;
            object-fit: contain;
        }
        @media (max-width: 768px) {
            width: 268px;
            height: 54px;
            border-radius: 6px;
            margin: 12px;
            font-size: 14px;
            padding-left: 0;
            justify-content: center;

            img {
                width: 32px;
                height: 32px;
            }
        }
    }
    &__menuItemTg {
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
    &__logoutButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 268px;
        height: 64px;
        border-radius: 8px;
        color: #fff;
        background: #d04243;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 70px;
        transition-duration: 0.3s;
        background: linear-gradient(90deg, #d04243 0%, #6a2222 100%);

        img {
            margin-right: 14px;
        }
        @media (max-width: 768px) {
            border-radius: 6px;
            margin: 12px;
            height: 54px;
            font-size: 14px;

            img {
                width: 25px;
                height: 25px;
            }
        }
    }
    &__logoutButton:hover {
        opacity: 0.8;
    }
    &__socialMedias {
        display: flex;
        margin-bottom: 25px;
        img {
            width: 32px;
            height: 32px;
            margin-bottom: 3px;
        }
        @media (max-width: 768px) {
            margin-top: 15px;
            margin-bottom: 44px;
        }
    }
    &__socialMediaLink {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        margin: 15px;
        text-decoration: none;
        font-size: 12px;
        line-height: 12px;
    }
    &__terms {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }
    &__helperDialog {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 268px;
        height: 64px;
        background-color: transparent;
        border: 1px solid #fff;
        border-radius: 8px;
        margin: 7px;
        color: #fff;
        font-style: normal;
        line-height: normal;
        transition-duration: 0.3s;
        padding-left: 64px;
        font-size: 16px;
        font-weight: 800;
        img {
            margin-right: 14px;
        }
        @media (max-width: 768px) {
            height: 54px;
            line-height: 12px;
            padding-left: 0;
            justify-content: center;
            font-size: 14px;
            margin: 12px;
        }
    }
    &__helperDialog:hover {
        background-color: #170e32;
    }
}

.new-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 268px;
    height: 64px;
    border-radius: 8px;
    margin: 7px;
    font-size: 16px;
    padding-right: 10px;
    font-style: normal;
    font-weight: 500;
    transition-duration: 0.3s;
    background: linear-gradient(to right, rgba(23, 14, 50, 1), rgba(70, 43, 152, 1));
    color: #fff;
    @media (max-width: 786px) {
        margin: 12px 12px 27px;
        height: 54px;
        font-size: 14px;
    }
    img[alt="AddIcon"] {
        width: 44px;
        height: 44px;
        object-fit: contain;
        margin-right: 14px;
    }
    .button__settingsIndicatior,
    .button__connectionIndicator {
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: 786px) {
            display: none;
        }
    }
    .dropdownContainer__dropdown:hover {
        background: inherit;
    }
    .dropdownContainer__dropdown_opened {
        background: linear-gradient(to right, rgb(23, 14, 50), rgb(70, 43, 152));
    }
    .dropdownContainer__dropdown_opened:hover {
        background: linear-gradient(to right, rgb(23, 14, 50), rgb(70, 43, 152));
    }

    .dropdownContainer__dropdown__selected {
        width: 278px;
        min-width: 278px;
        padding-left: 64px;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 16px;
        @media (max-width: 768px) {
            padding-left: 0;
            justify-content: center;
        }
        &__icon {
            position: static;
            width: 32px;
            height: 32px;
            margin-right: 14px;
        }
    }

    .button__connectionIndicator {
        @media (max-width: 768px) {
            display: flex;
            top: calc(100% + 7px);
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
    .dropdownContainer {
        width: 100%;
        max-width: 100%;
    }
    .dropdownContainer__dropdown__item {
        @media (max-width: 768px) {
            min-width: 228px;
            max-width: 228px;
            margin: 0 auto;
        }
    }
}
