.timeframeSelector {
  position: fixed;
  background: #ffffff;
  min-width: 305px;
  width: 305px;
  height: 195px;
  background-color: rgba(19, 14, 45, 1);
  color: white;
  z-index: 9999;
  padding: 1.5vh 1vw;
  border-radius: 0.75vh;
  box-shadow: 0 6px 12px #0000004d;
  transition-duration: 0.2s;
}
@media screen and (max-width: 768px) {
  .timeframeSelector {
    position: fixed;
    top: 38% !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.timeframeSelectorHeader {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5vh;
  font-size: 12px;
  line-height: 14px;
  line-break: normal;
}
.timeframeSelectorHeader_text {
  width: 67px;
}

.timeframeSelectorList {
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
  padding-left: 0;
}

.timeframeSelectorList div {
  width: 48px;
  height: 24px;
  flex: 0 0 calc(25% - 1vh);
  background: rgba(69, 65, 84, 1);
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 0.5vh 0;
  border-radius: 0.75vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeframeSelectorList img {
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  margin-right: 5px;
}

.timeframeSelectorList div:hover {
  border: 1px solid rgba(151, 71, 255, 1);
  cursor: pointer;
}
.timeframeHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.timeFrameItem {
  width: 37px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(69, 65, 84, 1);
  margin: 7px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 12px;
  transition-duration: 0.3s;
  cursor: pointer;
  user-select: none;
}
.timeFrameItem:hover {
  background-color: rgba(151, 71, 255, 1);
}
.timeFrameToggler {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
