.g-tumbler {
  min-width: 45px;
  height: 20px;
  min-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;

  border-radius: 4px;

  background-color: rgba(38, 35, 54, 1);

  overflow: hidden;

  transition: 0.2s;

  & input {
    display: none;
  }

  &-view {
    position: relative;

    flex-basis: 75px;
    flex-shrink: 0;

    height: 100%;
    padding: 2px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;

    font-size: 10px;
    font-weight: 700;

    translate: -11px 0;

    transition: 0.2s ease-in-out;

    cursor: pointer;

    &__circle {
      height: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 4px;
      width: 18px;

      background-color: rgba(151, 71, 255, 1);

      &::before,
      &::after {
        position: absolute;
        top: 50%;

        translate: 0 -50%;
      }

      &::before {
        content: "ON";
        left: 9px;
        font-size: 8px;
      }

      &::after {
        content: "OFF";
        right: 9px;
        font-size: 8px;
      }
    }
    &__circle_small {
      height: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 4px;
      width: 16px;
      height: 16px;

      background-color: rgba(151, 71, 255, 1);

      &::before,
      &::after {
        position: absolute;
        top: 50%;

        translate: 0 -50%;
      }

      &::before {
        content: "ON";
        left: 9px;
        font-size: 8px;
      }

      &::after {
        content: "OFF";
        right: 9px;
        font-size: 8px;
      }
    }
    &__circle_large {
      height: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 4px;
      width: 25.2px;
      height: 25.2px;

      background-color: rgba(151, 71, 255, 1);

      &::before,
      &::after {
        position: absolute;
        top: 50%;

        translate: 0 -50%;
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0px;
      }

      &::before {
        content: "ON";
        left: -2px;
        font-size: 12px;
      }

      &::after {
        content: "OFF";
        right: -2px;
        font-size: 12px;
      }
    }
  }
  &-view_small {
    translate: -10px 0;
  }
  &-view_large {
    translate: -15px 0;
  }

  &_checked &-view {
    translate: 11px 0;
  }
  &_checked &-view_small {
    translate: 10px 0;
  }
  &_checked &-view_large {
    translate: 16px 0;
  }
  &_checked {
    // background-color: var(--color-green-contrast);
  }
  //small size
  &_small {
    min-width: 40px;
    width: 40px;
    height: 20px;
    min-height: 20px;
  }
  &-view_small {
    flex-basis: 70px;
  }
  &-view_large {
    translate: -15px 0;
  }
  //large
  &_large {
    min-width: 63px;
    width: 63px;
    height: 32px;
    min-height: 32px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
  }
}
