.bar-chart {
  position: relative;
  height: 87%;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    padding-bottom: 25px;
  }
  canvas {
    background-image: url("../../../../../../assets/images/charts/line.png");
    background-position: 0 calc(50% - 8px);
    background-repeat: repeat-x;
    position: relative;
    z-index: 1;
    flex: 1 1;
  }
  &__bg {
    position: absolute;
    display: flex;
    top: 39.5%;
    transform: translateY(-50%);
    left: 44px;
    width: calc(100% - 86px);
    height: 86%;
  }

  &__line {
    position: absolute;
    bottom: 23px;
    transform: translateX(-50%);
    left: 0;
  }
}
