.strategy-settings {
  width: 100%;
  height: 94%;
  padding: 12px 5% 0 5%;
  margin: -12px 0 0 0;
  background: #130e2d;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 12;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 54px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
    &_modeContainer{
      width:58px;
      max-width:58px;
      max-height: 33px;
    }
  }
  &__switcher {
    min-width: 114px;
    min-height: 40px;
    width: 114px;
    height: 40px;
    background-color: #262336;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__item {
      min-width: 53px;
      width: 53px;
      min-height: 28px;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-family: Inter;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: left;
      color: white;

      &_active {
        background-color: #694ef0;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }
  &__description {
    display: flex;
    flex-direction: column;
    &_header {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 10px;
    }
    &_title {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 10px;
      span {
        font-weight: 600;
      }
    }
  }
  &__saveButton {
    position: absolute;
    bottom: 50px;
    width: 140px;
    min-height: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #694ef0;
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    bottom: 40px;
    cursor: pointer;
    margin-bottom: 50px;
    &_disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
