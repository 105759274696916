.strategy-cond {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__menu {
    width: 100%;
    background-color: #262336;
    height: 44px;
    max-height: 44px;
    min-height: 44px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    &__conditions {
      max-width: 85%;
      height: 44px;
      max-height: 44px;
      min-height: 44px;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      scrollbar-width: none;
      overflow-y: hidden;
      margin-left: 5px;
      position: relative;
    }

    &__condition {
      flex-shrink: 0;
      height: 32px;
      max-height: 32px;
      min-height: 32px;
      margin-top: 6px;
      margin-bottom: 6px;
      padding: 7px;
      margin-right: 5px;
      display: flex;
      overflow-x: scroll;
      scrollbar-width: none;
      overflow-y: hidden;
      font-family: Inter;
      font-size: 10px;
      color: white;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      border-radius: 4px;
      img {
        min-width: 14px;
        min-height: 14px;
        margin-left: 5px;
        cursor: pointer;
      }
      &_selected {
        background-color: #454154;
      }
    }
    &__condition:hover {
      background-color: #454154;
    }
    &__add {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      background-color: #694ef0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin-left: 5px;
      background-image: url(../../../../../../assets/images/Strategy/AddIcon.svg);
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: center center;
    }
  }

  &__group {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1vh;
    color: white;
    &__elem {
      display: flex;
      flex-direction: column;
      // align-items: center;
      width: 100%;
      color: white;
      //styleName: Text Form/Enter Text;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;

      &__delete {
        margin: 2% 15% 0 auto;
        padding: 10px 15px;
        border: none;
        background: none;
        outline: none;
        background-color: #ef5858;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: left;

        color: white;
        cursor: pointer;
        border-radius: 4px;
      }
    }

    &__add {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      font-size: 1.6vh;
      width: 120px;
      height: 32px;
      border-radius: 4px;
      gap: 10px;
      background-color: #694ef0;
      color: white;
      font-family: Inter;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 5px;
      }
    }

    &__line {
      // width: 85%;
      // height: 1.5px;
      // background: #e8e8e8;
      // margin: 5% 0;
    }
  }

  &__add-group {
    margin: 0 auto;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 1.6vh;
    width: 140px;
    height: 32px;
    border-radius: 4px;
    gap: 10px;
    background-color: #694ef0;
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0px;
  }
}
