.strategy-type-settings {
  width: 100%;
  // height: calc(100% + 12px);
  padding: 12px 5% 0 5%;
  margin: -12px 0 0 0;
  background: #130e2d;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 12px 12px 0 12px;
  }

  &__content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: #130e2d;
    margin-bottom: 40px;

    & input {
      width: 10vh;
      height: 25%;
      line-height: 2.75vh;
      padding: 2.25vh 1vh;
      outline: none;
      border: none;
      width: 100px;
      height: 44px;
      padding: 10px;
      border-radius: 4px;
      gap: 8px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      color: white;
      background-color: #262336;
      text-align: center;
    }

    &__elem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 3.5vh;
      background: #130e2d;
      @media screen and (max-width: 768px) {
        height: unset;
      }
      & h4 {
        white-space: nowrap;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        margin-right: 5px;
      }
      &__trailingLevelHeader {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: left;
      }
      &__numberContainer {
        max-width: 80px;
        width: 80px;
      }
    }

    &__take-profits {
      margin-top: 12px;
      max-height: 375px;
      overflow: scroll;

      // display: flex;
      // align-items: center;
      &__DCAInputs {
        display: flex;
        overflow: hidden;
        width: 84%;
        justify-content: flex-end;
        @media screen and (max-width: 768px) {

        }
      }

      &__TPInputs {
        display: flex;
        overflow: hidden;
        width: 84%;
        justify-content: flex-end;
        padding-right: 30px;
        @media screen and (max-width: 768px) {
      
        }
      }
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        height: 60px;
        input {
          width: 80px;
          height: 44px;
        }
      }
      &__itemHeader {
        width: 16.5%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &__itemInput {
        width: 25%;
        margin-left: 25px;
        min-width: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        @media screen and (max-width: 768px) {
          margin-left: 10px;
        }
        span {
          font-family: Nunito;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 5px;
        }
        &__lock {
          position: absolute;
          width: 24px;
          height: 24px;
          bottom: 10px;
          right: -30px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  &__saveButton {
    width: 95%;
    min-height: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #694ef0;
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    bottom: 40px;
    cursor: pointer;
    margin-bottom: 50px;
  }
}

.strategy-type-settings__content__take-profits__itemInput:hover
  .strategy-type-settings__content__take-profits__itemInput__lock {
  display: none;
}
.strategy-type-settings__content__take-profits__itemInput:focus
  .strategy-type-settings__content__take-profits__itemInput__lock {
  display: none;
}
