.strategy-selectorXL {
  display: flex;
  width: 100%;
  height: 54px;
  line-height: 38px;
  border-radius: 6px;
  gap: 5px;
  justify-content: flex-end;
  position: relative;
  @media screen and (max-width: 768px) {
    height: 48px;
    width: 98%;
  }

  &__select {
    width: 85%;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #262336 !important;
    border-radius: 6px !important;
    &-select {
      background-color: #262336 !important;
      border-radius: 6px !important;
      //background-image: url("../../../../../../assets/HomeLog/select-expand_down.svg");
      //background-repeat: no-repeat;
      //background-position: right 0.7rem top 50%;
      //background-size: 1.4rem auto;

      outline: none;
      border: none;
      border-radius: 6px;
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      height: 100%;

      cursor: pointer;

      appearance: none;
    }
  }

  &__settings {
    // width: 15%;
    // right: -17%;
    // top: 50%;
    // transform: translateY(-50%);
    // position: absolute;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: 2%;
    transition-duration: 0.3s;
    transform-origin: center;
    & img {
      height: 45%;
    }
    @media screen and (max-width: 768px) {
      width: 24px;
      min-width: 24px;
      height: 48px;
      & img {
        height: 21.6px;
      }
    }
  }
  &__delete {
    // width: 15%;
    // right: -26%;
    // top: 50%;
    // transform: translateY(-50%);
    // position: absolute;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: 2%;
    transition-duration: 0.3s;
    transform-origin: center;
    & img {
      height: 14px;
      width: 14px;
    }
  }
}
