// eslint-disable-next-line
.mainConatiner {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-image: url(../../assets/images/Profile/ProfileBackground.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 32px 80px;
    @media screen and (max-width: 1320px) {
        padding: 32px 40px;
    }
    @media screen and (max-width: 768px) {
        padding: 11px 18px 100px 18px;
    }
    &_hidden {
        display: none;
    }
    &_noScroll {
        overflow: hidden;
        max-height: 100%;
        height: 100;
    }
    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 44px;
        // border: 1px solid white;
        margin-bottom: 41px;
        &_logo {
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        &_logoMobile {
            display: none;
            @media screen and (max-width: 768px) {
                display: block;
            }
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
    &_nav {
        width: 500px;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
        @media screen and (max-width: 768px) {
            display: none;
        }
        &_item {
            cursor: pointer;
            color: white;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            transition-duration: 0.3s;
            &:hover {
                transform: scale(1.15);
            }
            &_disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
    &_content {
        width: 100%;
        min-height: 394px;
        height: 394px;
        // border: 1px solid white;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            min-height: 885px;
            justify-content: flex-start;
            gap: 5%;
        }
        @media screen and (max-width: 768px) {
            min-height: unset;
            height: unset;
            gap: 20px;
        }
        &_info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40.7%;
            min-width: 500px;
            gap: 20px;
            @media screen and (max-width: 1024px) {
                width: 100%;
                height: 47.5%;
            }
            @media screen and (max-width: 768px) {
                height: unset;
                min-width: unset;
                gap: 10px;
            }

            &_profileContainer {
                min-height: 69.5%;
                width: 100%;
                background: rgba(76, 58, 167, 0.31);
                border-radius: 16px;
                padding: 21px 15px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                @media screen and (max-width: 1320px) {
                    padding: 16px 18px;
                }
                @media screen and (max-width: 1024px) {
                    min-height: 300px;
                }
                @media screen and (max-width: 768px) {
                    justify-content: space-between;
                    background: rgba(45, 39, 73, 1);
                    border-radius: 12px;
                    padding: 16px 12px;
                    min-height: 208px;
                }
                &_general {
                    // border: 1px solid white;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    &_img {
                        width: 120px;
                        height: 120px;
                        border-radius: 16px;
                        @media screen and (max-width: 1320px) {
                            width: 90px;
                            height: 90px;
                        }
                        @media screen and (max-width: 768px) {
                            width: 72px;
                            height: 72px;
                        }
                    }
                    &_userContainer {
                        display: flex;
                        // align-items: center;
                        justify-content: space-between;
                        flex: 1;
                        color: white;
                        &_NameStatusContainer {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            max-width: 97%;
                            max-width: 320px;
                            gap: 10px;
                            @media screen and (max-width: 768px) {
                                max-width: 82%;
                            }
                        }
                        &_NameStatus {
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            gap: 18px;
                            color: white;
                            overflow: hidden;
                            @media screen and (max-width: 768px) {
                                gap: 13px;
                            }
                            &_name {
                                font-family: Inter;
                                font-size: 32px;
                                font-weight: 500;
                                line-height: 32px;
                                text-align: left;
                                max-width: 100%;
                                width: 100%;
                                word-break: break-all;
                                @media screen and (max-width: 1250px) {
                                    font-size: 28px;
                                }
                                @media screen and (max-width: 1120px) {
                                    font-size: 26px;
                                }
                                @media screen and (max-width: 768px) {
                                    font-size: 18px;
                                    font-weight: 500;
                                    line-height: 21.78px;
                                }
                            }
                            &_status {
                                display: flex;
                                align-items: center;
                                &_online {
                                    min-width: 14px;
                                    min-height: 14px;
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 50%;
                                    background: rgba(48, 217, 95, 1);
                                    margin-right: 4px;
                                }
                                &_offline {
                                    min-width: 14px;
                                    min-height: 14px;
                                    width: 14px;
                                    height: 14px;
                                    border-radius: 50%;
                                    background: rgb(204, 0, 0);
                                    margin-right: 4px;
                                }
                                font-family: Inter;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 14.52px;
                                text-align: left;
                                @media screen and (max-width: 768px) {
                                    font-family: Inter;
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 12.1px;
                                    text-align: left;
                                }
                            }
                        }
                        &_TypeData {
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            gap: 18px;
                            @media screen and (max-width: 768px) {
                                gap: 13px;
                            }
                            &_type {
                                font-family: Inter;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 16.94px;
                                text-align: left;
                                padding: 8px 12px;
                                background: rgba(151, 71, 255, 1);
                                border-radius: 12px;
                                white-space: nowrap;
                                @media screen and (max-width: 1120px) {
                                    font-size: 12px;
                                    line-height: 17px;
                                    padding: 6px 10px;
                                }
                                @media screen and (max-width: 768px) {
                                    font-family: Inter;
                                    font-size: 8px;
                                    font-weight: 700;
                                    line-height: 9.68px;
                                    text-align: left;
                                    padding: 6px 10px;
                                }
                            }
                            &_data {
                                font-family: Inter;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 14.52px;
                                text-align: left;
                                @media screen and (max-width: 768px) {
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 12.1px;
                                }
                            }
                        }
                        &_Settings {
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            justify-content: start;
                            position: relative;
                            width: 40px;

                            &_settings {
                                padding: 10px;
                                position: relative;
                                position: absolute;

                                // border: 1px solid white;
                                top: -20px;
                                right: -10px;
                                cursor: pointer;
                                &_menu {
                                    position: absolute;
                                    width: 224px;
                                    height: 192px;
                                    background: #262336;
                                    top: 30px;
                                    right: 10px;
                                    border-radius: 8px;
                                    padding: 18px 12px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: space-between;
                                    cursor: default;
                                    &_row {
                                        width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        img {
                                            cursor: pointer;
                                        }
                                        &_units {
                                            display: flex;
                                            align-items: center;
                                            span {
                                                width: 24px;
                                                height: 24px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                border-radius: 2px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &_statistics {
                    background: rgba(55, 43, 113, 1);
                    border-radius: 12px;
                    display: flex;
                    justify-content: space-between;
                    padding: 18px 25px;
                    &_item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 12px;
                        color: white;
                        &_value {
                            font-family: Inter;
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 29.05px;
                            text-align: left;
                            @media screen and (max-width: 768px) {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 21.78px;
                                text-align: left;
                            }
                        }
                        &_text {
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 14.52px;
                            text-align: left;
                            @media screen and (max-width: 768px) {
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 12.1px;
                                text-align: center;
                            }
                        }
                    }
                    @media screen and (max-width: 768px) {
                        padding: 20px 25px;
                    }
                }

                &__subscribe {
                    width: 100%;
                    height: 65px;
                    border-radius: 12px;
                    border: 1px solid #a700f6;
                    color: white;
                    font-size: 17px;
                    &_isFololowing {
                        background: #a700f6;
                    }
                }
            }
            &_additionalInfo {
                min-height: 25.3%;
                width: 100%;
                background: rgba(38, 35, 54, 1);
                border-radius: 16px;
                padding: 20px;
                color: white;
                position: relative;
                textarea {
                    resize: none;
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 19.36px;
                    text-align: left;
                    border: none;
                    outline: none;
                    background: rgba(38, 35, 54, 1);
                    color: white;
                    height: 100%;
                    width: 80%;
                    padding-right: 10px;
                }
                @media screen and (max-width: 768px) {
                    height: 100px;
                    min-height: 100px;
                    padding: 24px;
                    display: flex;
                    align-items: flex-end;
                }
                &_editButton {
                    width: 80px;
                    height: 32px;
                    border: 1px solid rgba(167, 0, 246, 1);
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    position: absolute;
                    right: 24px;
                    top: 24px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    img {
                        margin-right: 4px;
                    }
                    @media screen and (max-width: 768px) {
                        position: static;
                        right: 15px;
                        top: 15px;
                        width: 60px;
                        height: 24px;
                        font-size: 12px;
                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
                &_saveButton {
                    width: 90px;
                    height: 32px;
                    background-color: rgba(167, 0, 246, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    position: absolute;
                    color: white;
                    right: 24px;
                    top: 24px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    img {
                        margin-right: 4px;
                    }
                    @media screen and (max-width: 768px) {
                        position: static;
                        right: 15px;
                        top: 15px;
                        width: 60px;
                        height: 24px;
                        font-size: 12px;
                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
        &_statistics {
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            // border: 1px solid white;
            height: 100%;
            width: 54.3%;
            flex: 1 1;
            @media screen and (max-width: 1166px) {
                width: calc(100% - 525px);
            }
            @media screen and (max-width: 1024px) {
                width: 100%;
                margin-left: 0px;
                height: 47.5%;
                flex: none;
            }
            @media screen and (max-width: 768px) {
                height: unset;
            }
            &_chartNavigation {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                @media screen and (max-width: 1250px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                }

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                }
                &_header {
                    display: flex;
                    flex-direction: column;
                    &_text {
                        font-family: Inter;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 29.05px;
                        text-align: left;
                        color: white;
                        @media screen and (max-width: 1120px) {
                            font-size: 20px;
                        }
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                    }
                    &_textMobile {
                        display: none;
                        @media screen and (max-width: 768px) {
                            color: white;
                            font-family: Inter;
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 21.78px;
                            text-align: left;
                            display: block;
                        }
                    }
                    &_subText {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;
                        color: white;
                        @media screen and (max-width: 1120px) {
                            font-size: 11px;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                &_headerMob {
                    display: none;
                    @media screen and (max-width: 768px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
                &_chartTab {
                    flex-direction: row-reverse;
                    gap: 8px;
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                &_periodsContainer {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    color: white;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 16.94px;
                    text-align: left;
                    @media screen and (max-width: 768px) {
                        width: 100%;
                        font-size: 10px;
                        font-weight: 300;
                        line-height: 12.1px;
                        text-align: left;
                    }
                }
                &_periods {
                    width: 252px;
                    height: 32px;
                    background: #262336;
                    border-radius: 6px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2px;
                    @media screen and (max-width: 1120px) {
                        width: 230px;
                    }
                    @media screen and (max-width: 768px) {
                        flex: 1;
                        height: 38px;
                        padding: 4px;
                    }
                }
                &_period {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    flex: 1;
                    font-family: Inter;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12.1px;
                    text-align: left;
                    cursor: pointer;
                    border-radius: 4px;
                    &_active {
                        background-color: #694ef0;
                    }
                    @media screen and (max-width: 768px) {
                        font-family: Inter;
                        font-size: 8px;
                        font-weight: 400;
                        line-height: 9.68px;
                        text-align: left;
                    }
                }
            }
            &_chart {
                flex: 1;
                max-height: calc(100% - 60px);
                @media screen and (max-width: 768px) {
                    height: 144px;
                    min-height: 144px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
    &_pageNavigation {
        display: flex;
        width: 100%;
        height: 66px;
        gap: 20px;
        @media screen and (max-width: 1024px) {
            gap: 10px;
        }
        @media screen and (max-width: 768px) {
            overflow-x: scroll;
            overflow-y: hidden;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &_item {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            height: 66px;
            background: rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            text-align: left;
            border-bottom: 2px solid rgba(67, 67, 67, 1);
            overflow: hidden;
            padding: 25px 32px;
            color: white;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            transition-duration: 0.3s;
            &:hover {
                font-family: Inter;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.36px;
                text-align: left;
                border-bottom: 2px solid rgba(218, 71, 255, 1);
                background: linear-gradient(180deg, rgba(218, 71, 255, 0) 0%, rgba(218, 71, 255, 0.1) 100%);
            }
            @media screen and (max-width: 1220px) {
                font-size: 12px;
                padding: 18px 20px;
                &:hover {
                    font-size: 13px;
                }
            }
            @media screen and (max-width: 960px) {
                height: 50px;
            }
            @media screen and (max-width: 768px) {
                min-width: 156px;
                width: 156px;
                height: 66px;
                border-radius: 20px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-family: Inter;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.52px;
                text-align: left;
                padding: 0;
                gap: 8px;
            }
            &_active {
                font-family: Inter;
                font-size: 16px;
                font-weight: 700;
                line-height: 19.36px;
                text-align: left;
                border-bottom: 2px solid rgba(218, 71, 255, 1);
                background: linear-gradient(180deg, rgba(218, 71, 255, 0) 0%, rgba(218, 71, 255, 0.1) 100%);
                @media screen and (max-width: 1220px) {
                    font-size: 13px;
                }
            }
        }
    }
}
.publishedStrategiesContainer {
    width: 100%;
    // border: 1px solid white;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 26px;
    &_navigation {
        display: flex;
        align-items: center;
        gap: 13px;
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 125px;
            min-width: 125px;
            height: 38px;
            border: 1px solid rgba(151, 71, 255, 1);
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            border-radius: 10px;
            cursor: pointer;
            transition-duration: 0.3s;
            &:hover {
                background: rgba(151, 71, 255, 1);
                transform: scale(1.05);
            }
            &_active {
                background: rgba(151, 71, 255, 1);
                font-weight: bold;
            }
            @media screen and (max-width: 768px) {
                width: calc(33% - 10px);
                min-width: unset;
            }
        }
    }
    &_list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        &_item {
            flex: 1 1 calc(25% - 20px);
            height: 100px;
            max-width: calc(25% - 20px);
            min-height: 248px;
            background: rgba(28, 24, 68, 1);
            border-radius: 16px;
            padding: 24px 13px 24px 18px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (max-width: 1150px) {
                flex: 1 1 calc(33% - 20px);
                max-width: calc(33% - 20px);
            }
            @media screen and (max-width: 960px) {
                flex: 1 1 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
            @media screen and (max-width: 768px) {
                flex: 1 1 100%;
                max-width: 100%;
                padding: 18px;
                min-height: 232px;
                height: 232px;
            }
            &_row {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                color: white;
                &_name {
                    font-family: Inter;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24.2px;
                    text-align: left;
                    @media screen and (max-width: 768px) {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 21.78px;
                        text-align: left;
                    }
                }
                &_type {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                }
                &_mode {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    @media screen and (max-width: 768px) {
                        font-family: Inter;
                        font-size: 12px;
                        line-height: 14.52px;
                        text-align: left;
                    }
                }
                &_exchange {
                    display: flex;
                    align-items: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    img {
                        margin-right: 4px;
                    }
                    @media screen and (max-width: 768px) {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14.52px;
                        text-align: left;
                    }
                }
                &_pair {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16.94px;
                    }
                    @media screen and (max-width: 768px) {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14.52px;
                        text-align: left;
                        span {
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 14.52px;
                            text-align: right;
                        }
                    }
                }
                &_likes {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    img {
                        margin-right: 5px;
                    }
                }
                &_buttons {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                    padding-left: 20px;
                }
                &_openButton {
                    width: 51%;
                    height: 44px;
                    background: rgba(167, 0, 246, 1);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.94px;
                    text-align: left;
                    cursor: pointer;
                    transition-duration: 0.3s;
                    &:hover {
                        transform: scale(1.05);
                    }
                    @media screen and (max-width: 768px) {
                        max-width: 140px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 14.52px;
                        text-align: left;
                    }
                }
                &_openButton2 {
                    width: 51%;
                    height: 44px;
                    background: rgba(167, 0, 246, 1);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.94px;
                    text-align: left;
                    cursor: pointer;
                    transition-duration: 0.3s;
                    &:hover {
                        transform: scale(1.05);
                    }
                    @media screen and (max-width: 768px) {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 14.52px;
                        text-align: left;
                    }
                }
                &_copyButton {
                    width: 51%;
                    height: 44px;
                    border: 1px solid rgba(167, 0, 246, 1);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.94px;
                    text-align: left;
                    cursor: pointer;
                    transition-duration: 0.3s;
                    &:hover {
                        transform: scale(1.05);
                    }
                    &_disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                        &:hover {
                            transform: none;
                        }
                    }
                    @media screen and (max-width: 768px) {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 14.52px;
                        text-align: left;
                    }
                }
            }
        }
    }
    &_keysSettingsPopUpContainer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000a6;
        &_content {
            width: 414px;
            min-height: 500px;
            max-height: 80%;
            overflow: scroll;
            background: #1c1844;
            border-radius: 12px;
            padding: 32px 27px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &_title {
                width: 100%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Inter;
                font-size: 24px;
                font-weight: 700;
                line-height: 29.05px;
                text-align: left;
                img {
                    cursor: pointer;
                }
            }
            &_list {
                &_header {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 14.52px;
                    text-align: right;
                    color: white;
                    margin-bottom: 32px;
                }
                &_item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: white;
                    margin-bottom: 32px;
                    &_name {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19.36px;
                        text-align: left;
                    }
                    &_view {
                        width: 130px;
                        height: 44px;
                        border: 1px solid #a700f6;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        user-select: none;
                        border-radius: 8px;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 16.94px;
                        text-align: left;

                        img {
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}

.popUp {
    width: 90%;
    height: 76%;
    max-width: 955px;
    border-radius: 12px;
    background: #170e32;
    padding: 32px;
    padding-top: 40px;
    position: relative;
    overflow-x: scroll;
    scrollbar-width: none;
    @media (max-width: 768px) {
        max-width: 410px;
        width: 100%;
        min-height: 100%;
        overflow: scroll;
        border-radius: 0;
        padding: 54px 14px;
    }
    &::-webkit-scrollbar {
        display: none;
    }

    &__platformContainer {
        margin-bottom: 36px;
        display: flex;
        align-items: center;
        padding-left: 4px;
        margin-bottom: 30px;
        width: 100%;
        overflow-x: scroll;
        margin-bottom: 32px;
        background: rgba(73, 60, 139, 1);
        min-height: 52px;
        border-radius: 12px;

        &::-webkit-scrollbar {
            display: none;
        }
        scrollbar-width: none;
    }
    &__platform {
        min-width: 146px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        margin-right: 8px;
        user-select: none;
        cursor: pointer;
        color: #fff;
        overflow: scroll;
        img {
            width: 24px;
            height: 24px;
            margin-right: 18px;
        }
        &__selected {
            background: linear-gradient(96.5deg, #260058 0%, #694ef0 136.68%);

            color: #fff;
        }
        &__disabled {
            cursor: not-allowed;
            background: #261850;
        }
    }
}
.publishedBotsContainer {
    width: 100%;
    // border: 1px solid white;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 26px;
    &_navigation {
        display: flex;
        align-items: center;
        gap: 13px;
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 125px;
            min-width: 125px;
            height: 38px;
            border: 1px solid rgba(151, 71, 255, 1);
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            border-radius: 10px;
            cursor: pointer;
            transition-duration: 0.3s;
            &:hover {
                background: rgba(151, 71, 255, 1);
                transform: scale(1.05);
            }
            &_active {
                background: rgba(151, 71, 255, 1);
                font-weight: bold;
            }
            @media screen and (max-width: 768px) {
                width: calc(33% - 10px);
                min-width: unset;
            }
        }
    }
    &_list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        &_item {
            flex: 1 1 calc(25% - 20px);
            max-width: calc(25% - 20px);
            min-height: 406px;
            background: linear-gradient(180deg, rgba(48, 217, 96, 0) 0%, rgba(48, 217, 96, 0.25) 100%);
            border-radius: 16px;
            padding: 24px 13px 24px 18px;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            @media screen and (max-width: 1150px) {
                flex: 1 1 calc(33% - 20px);
                max-width: calc(33% - 20px);
            }
            @media screen and (max-width: 960px) {
                flex: 1 1 calc(50% - 20px);
                max-width: calc(50% - 20px);
            }
            @media screen and (max-width: 768px) {
                flex: 1 1 100%;
                max-width: 100%;
            }
            &_row {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                color: #acacac;
                margin-bottom: 20px;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                span {
                    font-weight: 600;
                    color: white;
                }
                &_name {
                    font-family: Inter;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21.78px;
                    text-align: left;
                    color: white;
                    margin-bottom: 12px;
                }
                &_status {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                    color: white;
                    margin-bottom: 16px;
                    span {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 14.52px;
                        text-align: left;
                    }
                }
                &_mode {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                }
                &_exchange {
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 4px;
                    }
                }
                &_pair {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16.94px;
                        text-align: right;
                        width: 50%;
                        text-align: left;
                    }
                }
                &_likes {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    img {
                        margin-right: 5px;
                    }
                }
                &_openButton {
                    width: 51%;
                    height: 44px;
                    background: rgba(167, 0, 246, 1);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16.94px;
                    text-align: left;
                    cursor: pointer;
                    transition-duration: 0.3s;
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            &_chart {
                height: 127px;
                width: 100%;
                // border: 1px solid white;
                margin-bottom: 24px;
                padding: 10px;
                &_header {
                    width: 100%;
                    height: 22px;
                    // border: 1px solid white;
                    margin-bottom: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &_value {
                        font-family: Inter;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 21.78px;
                        text-align: left;
                        color: #30d960;
                        span {
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14.52px;
                            text-align: left;
                        }
                    }
                    &_periods {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                    }
                    &_period {
                        width: 28px;
                        height: 20px;
                        font-family: Inter;
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 12.1px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #262336;
                        color: white;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
                &_content {
                    height: 84px;
                }
            }
            &_buttonsContainer {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &_details {
                    width: 44.8%;
                    max-width: 135px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #a700f6;
                    height: 44px;
                    border-radius: 12px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;
                    color: white;
                }
                &_copy {
                    width: 44.8%;
                    max-width: 135px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #a700f6;
                    height: 44px;
                    border-radius: 12px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;
                    color: white;
                }
            }
        }
    }
    &_botPopUpContainer {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000a6;
        &_content {
            width: 414px;
            min-height: 710px;
            max-height: 80%;
            overflow: scroll;
            background: #1c1844;
            border-radius: 12px;
            padding: 32px 27px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 768px) {
                width: 95%;
                max-width: 414px;
            }
            &_title {
                width: 100%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: Inter;
                font-size: 24px;
                font-weight: 700;
                line-height: 29.05px;
                text-align: left;
                margin-bottom: 32px;
                img {
                    cursor: pointer;
                }
            }
            &_chartNavigation {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                &_periods {
                    width: 252px;
                    height: 32px;
                    background: #262336;
                    border-radius: 6px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2px;
                }
                &_period {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    flex: 1;
                    font-family: Inter;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12.1px;
                    text-align: left;
                    cursor: pointer;
                    border-radius: 4px;
                }
                &_tabs {
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
                &_mobTabs {
                    display: none;
                    @media screen and (max-width: 768px) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 68px;
                        min-width: 64px;
                    }
                }
                &_mobTab {
                    min-width: 32px;
                    min-height: 32px;
                    background: rgba(69, 65, 84, 1);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &_active {
                        background: #694ef0;
                    }
                }
            }
            &_chart {
                width: 100%;
                height: 167px;
                min-height: 167px;
                margin-top: 32px;
                margin-bottom: 32px;
            }
            &_list {
                &_header {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 14.52px;
                    text-align: right;
                    color: white;
                    margin-bottom: 32px;
                }
                &_item {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: white;
                    margin-bottom: 32px;
                    &_name {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                    }
                    &_view {
                        width: 110px;
                        height: 36px;
                        border: 1px solid #a700f6;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        user-select: none;
                        border-radius: 8px;
                        img {
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}
.FFContainer {
    width: 100%;
    // border: 1px solid white;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 26px;
    &_header {
        width: 100%;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        margin-bottom: 20px;
        &_searchContainer {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 400px;
            height: 100%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
            &_icon {
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
            }
            input {
                width: 100%;
                height: 100%;
                background: rgba(52, 48, 96, 1);
                border-radius: 12px;
                outline: none;
                border: none;
                padding: 18px 24px;
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: white;
                @media screen and (max-width: 768px) {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                }
            }
        }
    }
    &_navigation {
        display: flex;
        align-items: center;
        gap: 13px;
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 125px;
            min-width: 125px;
            height: 38px;
            border: 1px solid rgba(151, 71, 255, 1);
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            border-radius: 10px;
            cursor: pointer;
            transition-duration: 0.3s;
            &:hover {
                background: rgba(151, 71, 255, 1);
                transform: scale(1.05);
            }
            &_active {
                background: rgba(151, 71, 255, 1);
                font-weight: bold;
            }
            @media screen and (max-width: 768px) {
                width: calc(50% - 10px);
                min-width: unset;
            }
        }
    }
    &_list {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;

        &_item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            &_info {
                display: flex;
                align-items: center;
            }
            &_name {
                font-family: Inter;
                font-size: 16px;
                font-weight: 600;
                line-height: 19.36px;
                text-align: left;
                color: white;
                margin-right: 20px;
                margin-left: 10px;
                width: 140px;
                cursor: pointer;
                @media screen and (max-width: 768px) {
                    width: 110px;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16.94px;
                    text-align: left;
                    margin-right: 10px;
                    margin-left: 8px;
                }
            }
            &_status {
                font-family: Inter;
                font-size: 10px;
                font-weight: 500;
                line-height: 12.1px;
                text-align: left;
                padding: 6px 10px;
                background: rgba(151, 71, 255, 1);
                border-radius: 6px;
                color: white;
                @media screen and (max-width: 768px) {
                    font-family: Inter;
                    font-size: 8px;
                    font-weight: 500;
                    line-height: 9.68px;
                    text-align: left;
                }
            }
            &_img {
                width: 32px;
                height: 32px;
                border-radius: 6px;
            }
            &_followButton {
                width: 110px;
                height: 36px;
                background: rgba(167, 0, 246, 1);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Inter;
                font-size: 12px;
                font-weight: 700;
                line-height: 14.52px;
                text-align: left;
                text-align: left;
                color: white;
                @media screen and (max-width: 768px) {
                    width: 90px;
                    font-family: Inter;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 12.1px;
                    text-align: left;
                }
            }
            &_unfollowButton {
                width: 110px;
                height: 36px;
                border: 1px solid rgba(167, 0, 246, 1);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                color: white;
                @media screen and (max-width: 768px) {
                    width: 90px;
                    font-family: Inter;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 12.1px;
                    text-align: left;
                }
            }
            @media screen and (max-width: 1150px) {
            }
            @media screen and (max-width: 960px) {
            }
        }
    }
}
