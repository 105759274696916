.time-selector {
    width: 100%;
    max-width: 132px;
    position: relative;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    user-select: none;
    &__title {
        height: 44px;
        min-height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 14px;
        padding-right: 14px;
        color: white;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        background: #262336;
        border-radius: 6px;
        cursor: pointer;
        &__opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    &__content {
        width: 100%;
        max-height: 130px;
        display: flex;
        flex-direction: column;
        background: #2a273c;
        overflow: scroll;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        &__item {
            width: 100%;
            max-height: 26px;
            height: 26px;
            min-height: 26px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 14px;
            color: white;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            background: #262336;
            transition-duration: 0.2s;
            cursor: pointer;
        }
        &__item:hover {
            background: #322e48;
        }
    }
}
