.date-picker {
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  & button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #694ef0;
    cursor: pointer;
    border-radius: 4px;
  }
  &__right {
    background-image: url(../../../../../../assets/icons/datePickerArrow.svg);
    background-repeat: no-repeat;
    background-size: 6px 10px;
    background-position: center center;
  }
  &__left {
    transform: rotate(180deg);
    background-image: url(../../../../../../assets/icons/datePickerArrow.svg);
    background-repeat: no-repeat;
    background-size: 6px 10px;
    background-position: center center;
  }

  &__content {
    background: #262336;
    width: 120px;
    height: 28px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #fff;
    margin: 0 10px;
  }
  &__disabled {
    background-color: #454154 !important;
  }
}
