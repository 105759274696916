.trades-table {
  width: 100%;
  color: #fff;
  min-width: 100%;
  table-layout: fixed;
  th {
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 10px;
    color: #7f8390;
    text-align: left;
  }
  td {
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 10px;
    color: #fff;
  }
  &__scroll {
    height: 100%;
    overflow-y: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &__red {
    color: #ff0000;
  }
  &__green {
    color: #30d960;
  }
}
.tradesTestTable {
  width: 100%;
}
.trades-tabler-header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  position: sticky;
  left: 0;
  top: 0;
  background-color: rgba(10, 7, 26, 1);
  margin-bottom: 10px;
  div {
    padding-left: 5px;
    padding-right: 8px;
    min-height: 30px;
    // padding-bottom: 16px;
    font-weight: 500;
    font-size: 10px;
    line-height: 30px;
    color: #7f8390;
    text-align: left;
    background-color: rgba(10, 7, 26, 1);
  }
  &_big {
    grid-template-columns: repeat(12, 1fr);
  }
}
.trades-table-item {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  div {
    padding-left: 5px;
    padding-bottom: 16px;
    font-weight: 500;
    font-size: 10px;
    color: #fff;
    padding-right: 8px;
  }
  &_big {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .trades-tabler-header {
    background-color: rgb(19, 14, 45);
    margin-bottom: unset;
    div {
      background-color: rgb(19, 14, 45);
      padding-left: 5px;
      line-height: 30px;
      min-height: 30px;
    }
  }
  .trades-table-item {
    div {
      padding-left: 5px;
    }
  }
}
.scrollbar-style > div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1) !important;
  background-color: transparent !important;
}

.scrollbar-style > div::-webkit-scrollbar {
  background-color: transparent !important;
}

.scrollbar-style > div::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: transparent !important;
}

.scrollbar-style > div::-webkit-scrollbar-thumb {
  background-color: rgb(151, 71, 255) !important;
}
