.modeSwitcher {
  width: 178px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
  background: rgba(38, 35, 54, 1);
  border-radius: 6px;
}
.modeSwitcher-backtest-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 32px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
.modeSwitcher-realtrade-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 32px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}
