.tg-bot-container {
    width: 100%;
    height: 94%;
    padding: 12px 3% 0 3%;
    margin: -12px 0 0 0;
    background: #130e2d;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 12;
    margin-bottom: 65px;
    overflow: scroll;
    @media screen and (max-width: 768px) {
        height:75%;
    }
}
.tg-bot-header {
    width: 100%;
    display: flex;
    &__back {
        min-width: 68px;
        min-height: 28px;
        background: #694ef0;
        border-radius: 4px;
        font-family: Inter;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        margin-bottom: 12px;
    }
}
.tg-bot-title {
    width: 100%;
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: left;
    margin-bottom: 10px;
}

.tg-bot-nav-item {
    width: 100%;
    height: 84px;
    background: rgba(68, 63, 94, 0.5);
    padding: 24px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin-bottom: 10px;
    color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
    transition-duration: 0.3s;
    cursor: pointer;
    &_disabled {
        cursor: not-allowed;
    }
    &_name {
        display: flex;
        align-items: center;
        &_disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
.tg-bot-nav-item img {
    margin-right: 12px;
}
.tg-bot-nav-item:hover {
    background: rgba(94, 87, 129, 0.5);
}
.tg-bot-nav-item_disabled:hover {
    background: rgba(68, 63, 94, 0.5);
}
