.navigationMenu {
    width: 111px;
    height: 32px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: rgba(73, 47, 203, 1);

    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    transition-duration: 0.4s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    position: absolute;
    &_header {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
        color: white;
    }
    &_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 5px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: white;
        width: 100%;
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 580px;
        min-width: 580px;
        position: absolute;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    &_item {
        cursor: pointer;
        transition-duration: 0.1s;
        width: 100px;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: white;
        &:hover {
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
        }
        &_selected {
            width:unset;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            background: #3400ac;
            border-radius: 12px;
            height: 37px;
            padding: 10px 13px;
        }
        &_disabled {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}
.navigationMenuOpened {
    width: 581px;
    height: 44px;
    background: linear-gradient(180deg, #27167b 0%, #4829e1 100%);
}
