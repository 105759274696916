.g-checkbox {
  & input {
    display: none;
  }

  &-view {
    width: 22px;
    height: 22px;
    aspect-ratio: 1 / 1;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #7B7B7B;

    transition: .2s;
    border-radius: 2px;

    cursor: pointer;

    &__check {
      width: 60%;
      height: 60%;

      opacity: 0;

      background-color: #9747FF;

      transition: .2s;
    }

    &_checked &__check {
      opacity: 1;
    }
  }

  &:hover &-view {
    border-color: #9747FF;

    &__check {
      background-color: #9747FF;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 24px;

    font-size: 14px;
    font-weight: 400;
    @media (max-width: 1100px) {
      font-size: 12px;
    }
    a {
      color: #9747FF;
      &:after {
        background-color: #9747FF;
      }
      &:hover {
        color: #852afd;
        &:after {
          background-color: #852afd;
        }
      }
    }
  }
}