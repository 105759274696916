.button {
    position: relative;
    display: flex;
    justify-content: center;
    width: 228px;
    height: 54px;
    background-color: #f3f3f3;
    border-radius: 8px;
    margin: 12px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    transition-duration: 0.3s;
}
.button:hover {
    background-color: #d6d6d6;
}
.accountsPopUpContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.accountsPopUp {
    width: 66%;
    height: 76%;
    border-radius: 12px;
    background: #fff;
    padding: 32px;
    &__errorInput {
        border: 1px solid #ae5858 !important;
    }
    &__error {
        color: #ae5858;
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        @media (max-width: 768px) {
            width: 82%;
            margin-bottom: 5px;
        }
    }
    &__tableAccountRow {
        display: flex;
        color: #fff;
        @media (max-width: 768px) {
            display: none;
        }
        div:not(:last-child) {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 58px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        @media (max-width: 768px) {
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
    }

    &__mobile {
        display: none;
        @media (max-width: 768px) {
            display: block;
            color: #fff;
            li {
                padding: 10px;
                .wrap {
                    display: flex;
                    margin-bottom: 10px;
                }
                .number {
                    flex-basis: 8%;
                    font-weight: 600;
                    font-size: 10px;
                }
                .name {
                    flex-basis: 17%;
                    font-weight: 600;
                    font-size: 12px;
                }
                .status {
                    flex-basis: 48%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .buttons {
                    flex-basis: 25%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .accountsPopUp__button_edit {
                        margin: 0 0 0 12px;
                    }
                }
            }
        }
        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            &-col {
                &:nth-child(2) {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 8px;
                img {
                    width: 18px;
                    height: 18px;
                    margin-top: 4px;
                }
            }
        }
    }
    &__tableAccountRow > div:nth-child(1) {
        flex-basis: 2%;
        @media (max-width: 768px) {
            order: 1;
            flex-basis: 8%;
            height: 39px;
            font-size: 10px;
            font-weight: 600;
        }
    }
    &__tableAccountRow > div:nth-child(2) {
        flex-basis: 15%;
        font-size: 16px;
        justify-content: start;
        padding-left: 15px;
        max-width: 135px;
        line-break: auto;
        word-break: break-all;
        color: #fff;
        @media (max-width: 990px) {
            flex-basis: 20%;
        }
        @media (max-width: 768px) {
            order: 2;
            flex-basis: 30%;
            font-size: 12px;
            font-weight: 600;
            height: 39px;
            padding-left: 10px;
        }
    }
    &__tableAccountRow > div:nth-child(3) {
        flex-basis: 4%;
        @media (max-width: 768px) {
            order: 3;
            flex-basis: 23%;
            height: 39px;
        }
    }
    &__tableAccountRow > div:nth-child(4) {
        flex-basis: 15%;
        padding-left: 30px;
        div {
            display: none;
        }
        @media (max-width: 768px) {
            order: 5;
            flex-basis: 23%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 32px;
            // margin-left: 10%;
            img {
                width: 18px;
                height: 18px;
            }
            div {
                display: block;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
    &__tableAccountRow > div:nth-child(5) {
        flex-basis: 10%;
        div {
            display: none;
        }
        @media (max-width: 768px) {
            order: 6;
            flex-basis: 55%;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 32px;
            img {
                width: 18px;
                height: 18px;
            }
            div {
                display: block;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
            }
        }
    }
    &__tableAccountRow > div:nth-child(6) {
        flex-basis: 15%;
        margin-right: 23%;
        div {
            display: none;
        }
        @media (max-width: 768px) {
            img {
                width: 18px;
                height: 18px;
            }
            div {
                display: block;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
            }
            order: 7;
            flex-basis: 22%;
            height: 31px;
            margin-right: 0%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &__tableAccountRow > div:nth-child(7) {
        flex-basis: 10%;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            img {
                width: 18px;
                height: 18px;
                display: none;
            }
            order: 4;
            flex-basis: 39%;
            display: flex;
            align-items: center;
            padding-left: 50px;
        }
    }
    &__tableEditPlatformAccountRow {
        display: flex;
        min-height: 100px;
        padding: 8px 4px 4px 4px;
        border-radius: 4px;
        background-color: #261850;
        margin-bottom: 20px;

        .accountsPopUp__button.accountsPopUp__button_active {
            @media (max-width: 768px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            position: relative;
            flex-wrap: wrap;
            height: unset;
            padding-bottom: 44px;
        }
    }
    &__tableEditPlatformAccountRow > div:not(:last-child) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 20px;
    }
    &__tableEditPlatformAccountRow > div:nth-child(1) {
        flex-basis: 3%;
        color: #fff;
        @media (max-width: 768px) {
            flex-basis: 7%;
            order: 1;
            padding-top: 8px;
        }
    }
    &__tableEditPlatformAccountRow > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        box-sizing: border-box;
        height: 100%;
        margin-right: 10px;
        input {
            width: 100%;
            height: 44px;
            background-color: #262336;
            outline: none;
            border: none;
            border-radius: 4px;
            color: #fff;
            margin-bottom: 8px;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 18px;
        }
        input::placeholder {
            color: #CBCBCB;
            font-size: 10px;
            font-style: normal;
        }
        @media (max-width: 768px) {
            order: 2;
            flex-basis: 76%;
            margin-right: 0;
            min-height: 96px;
            padding-bottom: 0;
            input {
                height: 36px;
                min-height: 36px;
                padding-left: 10px;
                margin-bottom: 12px;
            }
        }
    }
    &__tableEditPlatformAccountRow > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-basis: 30%;
        margin-right: 12%;
        box-sizing: border-box;
        height: 100%;
        padding-bottom: 0;
        input {
            width: 100%;
            height: 44px;
            background-color: #262336;
            outline: none;
            border: none;
            border-radius: 4px;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-left: 18px;
            margin-bottom: 8px;
            margin-top: 52px;
        }
        input::placeholder {
            color: #CBCBCB;
            font-size: 10px;
            font-style: normal;
        }
        @media (max-width: 768px) {
            order: 4;
            flex-basis: 76%;
            margin-left: 7%;
            min-height: 36px;
            padding-bottom: 0;
            input {
                height: 36px;
                padding-left: 10px;
                margin-top: unset;
            }
        }
    }
    &__tableEditPlatformAccountRow > div:nth-child(4) {
        flex-basis: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        div:not(:last-child) {
            display: flex;
            align-items: flex-start;
            margin-bottom: 18px;
            img {
                margin-right: 24px;
            }
        }
        @media (max-width: 768px) {
            order: 3;
            height: 50px;
            flex-basis: 15%;
            padding-bottom: 0;
            padding-top: 8px;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 0;
            }
            input {
                height: 36px;
            }
        }
    }
    &__connectedCircle {
        background: #30d95f;
        min-width: 12px;
        min-height: 12px;
        max-height: 12px;
        max-width: 12px;
        border-radius: 50%;
        @media (max-width: 768px) {
            min-width: 8px;
            min-height: 8px;
            max-height: 8px;
            max-width: 8px;
        }
    }
    &__notConnectedCircle {
        background: #f3ba2f;
        min-width: 12px;
        min-height: 12px;
        max-height: 12px;
        max-width: 12px;
        border-radius: 50%;
        @media (max-width: 768px) {
            min-width: 8px;
            min-height: 8px;
            max-height: 8px;
            max-width: 8px;
        }
    }
    &__button {
        width: 50px;
        height: 22px;
        min-height: 22px;
        max-height: 22px;
        text-align: center;
        font-size: 10px;
        line-height: 10px;
        font-weight: 700;
        border-radius: 4px;
        user-select: none;
        &_reload {
            @media (max-width: 768px) {
                display: none;
            }
        }
        &_edit {
            background-color: transparent;
            color: #8B57CF;
            font-size: 10px;
            margin-right: 12px;
            margin-left: 24px;
            @media (max-width: 768px) {
                width: 40px;
                height: 19px;
                font-style: normal;
                font-weight: 700;
                order: 2;
            }
        }
        &_active {
            color: #fff !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgb(104,189,133);
            background: linear-gradient(47deg, rgba(104,189,133,1) 0%, rgba(48,87,61,1) 100%);
            @media (max-width: 768px) {
                width: 44px;
                height: 18px;
                min-height: 18px;
                font-size: 12px;
                line-height: 8px;
                font-style: normal;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &_save {
            color: #8B57CF !important;
            margin-right: 15px;
            font-size: 10px;
            @media (max-width: 768px) {
                width: 44px;
                height: 18px;
                min-height: 18px;
                font-size: 12px;
                line-height: 8px;
                font-style: normal;
                margin-right: 0;
                margin-bottom: 6px;
                position: absolute;
                right: 0;
            }
        }
        &_switch {
            color: #fff !important;
            background: #bebebe;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 8px;
                font-style: normal;
                width: 40px;
                height: 18px;
                min-height: 18px;
            }
        }
        &_delete {
            background: #ae5858;
            width: 120px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 16px 10px;
            background: linear-gradient(90deg, #D04243 0%, #6A2222 100%);
            margin-top: 16px;
            @media (max-width: 768px) {
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                bottom: 8px;
            }
        }
    }
}
