.auth {
  width: 100vw;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  font-family: "Inter", sans-serif;
  color: #fff;
  background: url(../../assets/images/MenuPage/MenuDesktopBackground.webp) center center / cover;
  @media (max-width: 1100px) {
    background-position: left top;
  }
  &.sign-up {
    background: url(../../assets/images/auth/RegistrationBackground.webp) center center / cover;
    .auth-form {
      top: 174px;
      max-height: calc(75% - 60px);
      overflow: auto;
      @media (max-width: 1100px) {
        top: 122px;
        max-height: calc(100% - 230px);
      }
    }
    .auth-form-controls {
      .g-button.blue-bg {
        width: 227px !important;
        max-width: 227px !important;
        margin: 0 auto;
        @media (max-width: 1100px) {
          width: 100% !important;
          max-width: 300px !important;
        }
      }
    }
  }
  &.remember-pass {
    background: url(../../assets/images/auth/ForgotPasswordBackground.webp) center center / cover;
    @media (max-width: 1100px) {
      background-position: center center;
    }
    .auth-form-controls {
      .g-button.blue-bg {
        width: 227px !important;
        max-width: 227px !important;
        margin: 0 auto;
        @media (max-width: 1100px) {
          width: 100% !important;
          max-width: 300px !important;
        }
      }
    }
  }
  &.remember-pass__step-2 {
    .auth-form-body {
      margin-bottom: 32px !important;
    }
  }
  &__bg {
    position: relative;

    max-width: 566px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 2;

    pointer-events: none;
    overflow: hidden;

    & img {
      position: absolute;
      translate: -50% -50%;
    }

    &_blue {
      background: linear-gradient(to bottom, #bae6ff 40%, #5eadff 110%);

      & img {
        top: 75%;
        left: 100%;
      }
    }

    &_purple {
      background: linear-gradient(to bottom, #f4d2ff -10%, #b5b7ff 110%);

      & img {
        top: 65%;
        left: 35%;
      }
    }

    &_gray {
      background: linear-gradient(to bottom, #f1f1f1 40%, #d9d9d9 140%);

      & img {
        top: 70%;
        left: -15%;
      }
    }

    &_red {
      background: linear-gradient(to bottom, #ffd3cc 20%, #dcb3bd 120%);

      & img {
        top: 95%;
        left: 60%;
      }
    }

    &_green {
      background: linear-gradient(to bottom, #c0fbab 10%, #98cb85 70%);

      & img {
        top: 60%;
        left: -20%;
      }
    }

    &_emerald {
      background: linear-gradient(to bottom, #e4e3b8, #94c8bc);

      & img {
        top: 60%;
        left: -20%;
      }
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  &-main {
    position: relative;

    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__logo {
      position: absolute;

      top: 100px;
      left: 50%;
      translate: -50% 0;

      display: flex;
      flex-direction: column;
      align-items: center;

      color: black;
      font-weight: 700;
      text-transform: uppercase;

      & img {
        width: 160px;

        @media (max-width: 1100px) {
          width: 100px;
        }
      }

      @media (max-width: 1100px) {
        top: 44px;

        font-size: 12px;
      }
    }

    &__terms {
      position: absolute;
      bottom: 18px;
      left: 50%;
      translate: -50% 0;

      font-size: 12px;
      color: #fff;

      opacity: 0.5;

      transition: 0.2s;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }

    &-steps {
      position: absolute;
      left: 50%;
      bottom: 45px;
      translate: -50% 0;

      max-width: 534px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      @media (max-width: 1100px) {
        width: 80%;
      }

      &__step {
        width: 32px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #2c2c2c;

        border-radius: 50%;

        font-size: 12px;
        font-weight: 800;
        color: white;

        transition: 0.2s;

        &_active {
          background-color: #9747ff;
        }
      }

      &__line {
        flex-grow: 1;

        height: 1px;
        background: url("../../assets/images/auth/line.svg") center no-repeat;

        &:last-child {
          display: none;
        }
      }
    }
  }

  &-form {
    position: relative;
    position: absolute;
    top: 200px;
    left: 50%;
    translate: -50% 0;
    background: #0d0920;
    border-radius: 12px;
    max-width: 548px;
    width: 100%;

    padding: 44px 32px 64px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &_text {
      position: absolute;
      left: 50%;
      translate: -50% 0;
      top: 30px;
      color: white;
    }

    @media (max-height: 905px) {
      max-height: calc(100% - 240px);

      overflow-y: scroll;
    }

    @media (max-width: 1100px) and (max-height: 850px) {
      max-height: calc(100% - 154px);
    }

    @media (max-width: 1100px) {
      top: 94px;
      padding: 32px 14px;

      align-items: center;
    }

    &_disabled &-header,
    &_disabled &-body {
      opacity: 0.25;
      pointer-events: none;
      user-select: none;

      @media (max-width: 1100px) {
        opacity: 0;
      }
    }

    &__back {
      display: flex;
      align-items: center;
      gap: 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      transition: 0.2s;
      align-self: flex-start;
      margin-bottom: 42px;

      & svg path {
        fill: #fff;
        transition: 0.2s;
      }

      &:hover {
        color: #9747ff;

        & svg path {
          fill: #9747ff;
        }
      }
    }

    &__later {
      display: flex;
      align-items: center;
      gap: 15px;

      margin: 30px auto 0 auto;

      color: var(--color-gray);
      font-size: 14px;
      font-weight: 400;

      cursor: pointer;

      transition: 0.2s;

      & svg path {
        fill: var(--color-gray);

        transition: 0.2s;
      }

      &:hover {
        color: var(--color-gray-hover);

        & svg path {
          fill: var(--color-gray-hover);
        }
      }
    }

    &-header {
      width: 100%;
      margin-bottom: 18px;

      &__title {
        font-size: 32px;
        font-weight: 900;
        color: #fff;

        @media (max-width: 1100px) {
          font-size: 24px;
        }
      }

      &__description {
        font-weight: 500;
        font-size: 14px;
        color: #fff;

        @media (max-width: 1100px) {
          font-size: 12px;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 18px;

      width: 100%;
      margin-bottom: 12px;

      &-tip {
        margin-top: 12px;
        margin-bottom: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        &__title {
          font-size: 14px;
          font-weight: 300;
          color: #fff;
        }

        &-timer {
          display: flex;
          gap: 15px;

          &__time {
            font-size: 14px;
            font-weight: 700;
            color: #fff;
          }

          & .link {
            font-size: 14px;
            font-weight: 600;
            color: #694ef0;
            &:before {
              background-color: #694ef0;
            }
            &:hover {
              color: #5b2a9a;
              &:after {
                background-color: #5b2a9a;
              }
            }
          }
        }
      }
    }

    &__under-body-link {
      font-size: 12px;
      color: #9747ff;
      @media (max-width: 1100px) {
        margin-left: auto;
      }
      &:after {
        background-color: #9747ff;
      }
      &:hover {
        color: #5b2a9a;
        &:after {
          background-color: #5b2a9a;
        }
      }
    }

    &-captcha {
      width: 100%;
      text-align: center;
      margin: 32px 0;
    }

    &-controls {
      width: 100%;
      margin-bottom: 32px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 1100px) {
        flex-direction: column !important;
      }

      .g-button {
        width: calc(50% - 7.5px);
        max-width: calc(50% - 7.5px);
        height: 54px !important;
        color: #fff !important;
        font-size: 14px !important;

        @media (max-width: 1100px) {
          width: 100% !important;
          max-width: 300px !important;
          font-size: 12px !important;
          display: flex;
          height: 44px !important;
          align-items: center;
          text-align: center;
          justify-content: center;
          padding: 0;
          min-height: 44px !important;
          max-height: 44px !important;
        }

        &.blue-bg {
          background: #9747ff !important;
          font-weight: bold !important;
          @media (max-width: 1100px) {
            margin-bottom: 15px;
          }
          &:hover {
            background: #5b2a9a !important;
          }
        }
        &.blue-border {
          border: 1px solid #d3d3d3 !important;
          font-weight: 500 !important;
          &:hover {
            border-color: #9747ff !important;
          }
        }
      }

      @media (max-width: 1100px) {
        flex-direction: column;

        margin-bottom: 44px;

        & .g-button {
          width: 65%;
        }
      }

      &_half {
        width: 50%;
        margin: 0 auto;

        @media (max-width: 1100px) {
          width: 100%;
        }
      }
    }

    &-oauth {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      @media (max-width: 1100px) {
        flex-direction: column;
      }

      &__title {
        flex-basis: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }

      &-buttons {
        flex-basis: 100%;

        display: flex;
        align-items: center;
        gap: 15px;

        @media (max-width: 1100px) {
          width: 65%;
        }

        &__button {
          flex-basis: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          padding: 10px;

          border: 1px solid var(--color-gray);
          border-radius: 8px;

          cursor: pointer;

          transition: 0.2s;

          &:hover {
            border-color: var(--color-gray-hover);
          }
        }
      }
    }

    &-confirmation {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      margin-bottom: 32px;

      @media (max-width: 1100px) {
        width: 100%;
        max-width: 300px;
        align-items: flex-start;
      }
    }

    &-authenticators {
      width: 100%;
      margin: 14px 0 32px 0;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 64px;

      @media (max-width: 1100px) {
        gap: 20px;
      }

      &-element {
        flex-basis: 172px;
        aspect-ratio: 1 / 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 14px;

        padding: 24px;

        background: #262336;
        border-radius: 8px;

        transition: 0.2s;

        cursor: pointer;
        color: #fff;

        @media (max-width: 1100px) {
          flex-basis: 50%;
          gap: 16px;
        }

        &:hover:not([disabled]),
        &_active {
          background: #9747ff;
        }

        &:disabled {
          cursor: not-allowed;
        }

        & img {
          width: 72px;
          height: 72px;
        }

        &__title {
          display: flex;
          flex-direction: column;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          color: #fff;
          text-align: center;
        }
      }
    }

    &-modal {
      position: absolute;
      top: 42%;
      left: 50%;
      translate: -50% -50%;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      text-align: center;

      &__title {
        width: 236px;

        font-size: 24px;
        font-weight: 800;
        color: #fff;
      }

      &__description {
        width: 382px;

        font-size: 16px;
        font-weight: 500;
        color: #fff;

        @media (max-width: 1100px) {
          width: 339px;
        }
      }
    }
  }

  @media (max-width: 550px) {
    padding: 0 10px;
  }
}
