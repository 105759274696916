.tabSwitchWrapper {
  width: 212px;
  min-width: 212px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .tabSwitchWrapper {
    min-height: 40px;
  }
}

.tabSwitcherItem {
  width: 50%;
  border: none;
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  border-radius: 0;
  min-height: 28px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #262336;
  outline: none;
  cursor: pointer;
}

.active {
  background: #694ef0;
  border-radius: 2px;
  font-weight: 700;
}
