.strategy-switcher {
  display: flex;
  background: #262336;
  align-items: center;
  border-radius: 6px;
  gap: 1px;
  min-height: 40px;
  width: 210px;
  color: white;
  padding: 0px 5px 0px 5px;
  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 4px;
  }

  &__option {
    font-size: 14px;
    font-weight: 600;
    flex: 1 1 0;
    width: 100px;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 10px 5px 10px;
    height: 28px;
    line-height: 28px;
    border-radius: 6px;
    color: white;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0px;
    @media screen and (max-width: 768px) {
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
    }
  }
}
