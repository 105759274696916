.strategy-settings {
  width: 100%;
  height: 94%;
  padding: 12px 5% 0 5%;
  margin: -12px 0 0 0;
  background: #130e2d;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 12;
  @media screen and (max-width: 768px) {
    height:100%;
    overflow: scroll;
    position: static;
  }
  &__session {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      margin-bottom: 22px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      &__text {
        font-size: 14px;
        max-width: 53%;
        white-space: break-spaces;
      }
      &__checkbox {
        width: 28px;
        height: 28px;
        img {
          margin-right: 12px;
          width: 28px;
          height: 28px;
        }
      }
      &__input {
        width: 55%;
        height: 44px;
        max-width: 180px;
        min-height: 44px;
        background-color: #262336;
        text-align: left;
        padding-left: 15px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: white;
        border: none;
        outline: none;
        border-radius: 6px;
        &_error {
          border: 1px solid red;
        }
      }

      &__colorPicker {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0px;
        text-align: left;
        display: flex;
        align-items: center;
        img {
          margin-left: 12px;
          width: 28px;
          height: 28px;
        }
      }
      &__timeZoneDrop {
        max-width: 33%;
        width: 33%;
      }
    }
  }
  &__content {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: #130e2d;

    & input {
      width: 10vh;
      height: 25%;
      line-height: 2.75vh;
      padding: 2.25vh 1vh;
      outline: none;
      border: none;
      width: 100px;
      height: 44px;
      padding: 10px;
      border-radius: 4px;
      gap: 8px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      color: white;
      background-color: #262336;
      text-align: center;
    }

    &__elem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 3vh;
      background: #130e2d;
      & h4 {
        white-space: nowrap;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        margin-right: 5px;
      }
    }

    &__take-profits {
      margin-top: 12px;
      max-height: 375px;
      overflow: scroll;

      // display: flex;
      // align-items: center;
      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        height: 60px;
        input {
          width: 80px;
          height: 44px;
        }
      }
      &__itemHeader {
        width: 38%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      &__itemInput {
        width: 25%;
        margin-left: 6.5%;
        min-width: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
          font-family: Nunito;
          font-size: 10px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: left;
          margin-bottom: 5px;
        }
      }
      // text-align: left;
      // & thead tr th {
      //   font-weight: 400;
      //   font-family: Nunito;
      //   font-size: 10px;
      //   font-weight: 500;
      //   line-height: 14px;
      //   letter-spacing: 0px;
      //   text-align: left;
      // }
      // & tbody tr {
      //   height: 6vh;
      //   & h4{
      //     width:100px;
      //   }
      //   & th {
      //     width: 100px;
      //   }
      //   & th input {
      //     height: 4vh;
      //     width: 80px;
      //     height: 44px;
      //   }
      // }
    }
  }
  &__saveButton {
    position: absolute;
    bottom: 50px;
    width: 140px;
    min-height: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #694ef0;
    color: white;
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    bottom: 40px;
    cursor: pointer;
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
      width:100%;
      position: static;
      bottom: 0px;
    }
    &_disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
