.publishStrategyPopUpContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    top: 0;
    right: 0;
}
.publishStrategyPopUp {
    width: 93%;
    min-height: 272px;
    background: #130e2d;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 18px 12px;
    &_header {
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.94px;
        text-align: left;
        color: white;
        margin-bottom: 22px;
    }
    &_typeContainer {
        display: flex;
        align-items: center;
        width: 200px;
        height: 32px;
        border-radius: 4px;
        padding: 2px;
        background-color: #262336;
        margin-bottom: 10px;
        &_type {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-family: Inter;
            font-size: 10px;
            font-weight: 600;
            line-height: 12.1px;
            text-align: left;
            color: white;
            border-radius: 4px;
        }
    }
    &_text {
        width: 100%;
        padding: 0px 24px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 300;
        line-height: 14.52px;
        text-align: left;
        color: white;
        margin-bottom: 31.5px;
    }
    &_image {
        margin-bottom: 5px;
    }
    &_title {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        text-align: center;
        color: white;
        margin-bottom: 12px;
    }
    &_buttonsContainer {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        &_button {
            flex: 1;
            height: 100%;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Inter;
            font-size: 10px;
            font-weight: 600;
            line-height: 12.1px;
            text-align: left;
            color: white;
        }
    }
}
