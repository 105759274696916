.strategy-element {
  position: relative;

  width: 100%;
  min-height: 54px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  &__name {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    max-width: 55%;
    overflow: hidden;
  }
  &__mode {
    font-family: Inter;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: #b8b8b8;
  }
  &::after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 50%;
    translate: -50% 0;

    width: 80%;
    height: 2px;

    background: radial-gradient(circle at center, #00000030, #00000010);
  }

  &-controls {
    position: relative;

    display: flex;
    align-items: center;
    gap: 10px;

    &-misc {
      cursor: pointer;
      padding: 5px 10px;
      @media screen and (max-width: 768px) {
        cursor: pointer;
        padding: 8px 15px;
      }

      &-dots {
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 10px 0;
        @media screen and (max-width: 768px) {
          gap: 4px;
          padding: 4px 0;
        }

        &__dot {
          width: 4px;
          height: 4px;
          aspect-ratio: 1 / 1;

          border-radius: 50%;

          background-color: var(--color-gray);

          transition: 0.2s;
          @media screen and (max-width: 768px) {
            width: 6px;
            height: 6px;
          }
        }
      }

      &-options {
        position: relative;
        position: absolute;
        bottom: 25px;
        left: -124px;
        translate: -10px 100%;

        max-width: 0;
        max-height: 0;

        display: flex;
        flex-direction: column;

        opacity: 0;
        pointer-events: none;
        z-index: 5;
        transition: max-height 0.2s 0s, max-width 0.2s 0s, opacity 0.2s;
        @media screen and (max-width: 768px) {
          left: -175px;
        }
        &_container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          background: #262336;
          box-shadow: 0 15px 50px 0 #00000033;
          border-radius: 6px;
          padding: 8px 8px;
          @media screen and (max-width: 768px) {
            box-shadow: none;
          }
        }
        &-copyMenuHedge {
          position: absolute;
          width: 137px;
          height: 80px;
          background-color: #262336;
          top: 0;
          right: -137px;
          border-radius: 4px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
        }
        &-copyMenuOneWay {
          position: absolute;
          width: 137px;
          height: 56px;
          background-color: #262336;
          top: 0;
          right: -137px;
          border-radius: 4px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          @media screen and (max-width: 768px) {
            height: unset;
          }
        }
        @media screen and (max-width: 768px) {
          position: absolute;
          bottom: 30px;
          left: -175px;
          translate: -10px 100%;
          max-width: 0;
          max-height: 0;
          padding: 8px 8px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          background: #262336;
          width: 140px;
          height: 100px;
        }
        &_visible {
          opacity: 1;
          max-width: 200px;
          width: 130px;
          height: 128px;
          max-height: 200px;

          pointer-events: auto;

          transition: max-height 0.2s, max-width 0.2s, opacity 0.2s 0.2s;

          @media screen and (max-width: 768px) {
            height: 130px;
            min-height: unset;
            height: unset;
            max-height: unset;
            border-radius: 4px;
          }
        }

        &__option {
          cursor: pointer;
          font-family: Inter;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0px;
          text-align: left;
          color: white;
          @media screen and (max-width: 768px) {
            cursor: pointer;
            font-family: Inter;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0px;
            text-align: left;
            color: white;
          }
          &_red {
            color: #b22424;
            text-decoration: underline;
          }
        }
        &__optionCopy {
          cursor: pointer;
          font-family: Inter;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0px;
          text-align: left;
          color: white;
          display: flex;
          justify-content: space-between;

          img {
            width: 6px;
          }
          @media screen and (max-width: 768px) {
            cursor: pointer;
            font-family: Inter;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0px;
            text-align: left;
            color: white;
          }
          &_red {
            color: #b22424;
            text-decoration: underline;
          }
        }
      }
      &-star {
        position: absolute;
        left: -15px;
        height: 17px;
        width: 17px;
        top: 50%;
        translate: 0 -50%;
        opacity: 0;
        transition: 0.2s;
        @media screen and (max-width: 768px) {
          opacity: 1;
          left: -30px;
          height: 25px;
          width: 25px;
          top: 50%;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    & .g-button {
      font-size: 12px;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
.strategy-element:hover .strategy-element-controls-misc-star {
  opacity: 1;
}
